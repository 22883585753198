import React, { useEffect, useState } from 'react'
import CardGroup from 'react-bootstrap/CardGroup';
import first from "../assets/img/first.svg";
import second from "../assets/img/second.svg";
import third from "../assets/img/third.svg"
import forth from "../assets/img/fourth.svg"
import fifth from "../assets/img/fifth.svg"
import sixth from "../assets/img/sixth.svg"
import track from "../assets/img/track.svg"
import axios from 'axios';
import { Modal } from "react-bootstrap";
import Spinner from './Spinner';
import Tableskeleton from './Tableskeleton';
import Accordion from 'react-bootstrap/Accordion';
import moment from "moment/moment";
import { toast } from "react-toastify";
import { useForm } from 'react-hook-form';
import { handleError } from "../Utilities/HandleError";
import CountdownTimer from './CountdownTimer';
import CountDownPlus from './CountDownPlus';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap'
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Multiselect from 'multiselect-react-dropdown';
import DatePicker from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import { findAllByTestId } from '@testing-library/react';
const Dashboard = () => {
  const [cardopen, setCardOpen] = useState("allbid");
  const [isActive, setIsActive]= useState(1);
  const [cloneBid,setCloneBid]=useState(false)
  const [loadingBid, setLoadingBid] = useState(false);
  const [vehicleList, setVehicleList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [time, setTime] = useState(new Date());
  const [maximumDate, setMaximumDate] = useState(new Date());
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [groupName,setGroupName]=useState([])
  const [groupError, setGroupError] = useState('');
  const [addData, setAddData] = useState({
    productName: '', productNameerr: '',
    weight: '', weighterr: '',
    height: '', heighterr: '',
    length: '', lengtherr: '',
    dealerName: '', dealerNameerr: '',
    doNumber: '', doNumbererr: '',
    locationName: '', locationNameerr: '',
    mobileNumber: '', mobileNumbererr: '',
    state: '', stateerr: '',
    description: '', descriptionerr: '',
    city: '', cityerr: '',
    pincode: '', pincodeerr: '',
    addresserr: '',
    publicIdErr: ''
  })
  const currentDate = moment();
  const [startDate, setStartDate] = useState(new Date());
  const [endTime, setEndTime] = useState(moment());
  const [truckArrival, setTruckArrival] = useState(moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'));
  const [maxTime, setMaxTime] = useState(moment(currentDate).add(8, 'hours'));
  const [dateValue, setDateValue] = useState(moment())
  const [publicId, setPublicId] = useState("")
    // { / *******************ADDRESS 1 States Start********************/ }
    // const [dealerName, setDealerName] = useState("")
    // const [doNumber, setDoNumber] = useState("")
    // const [locationName, setLocationName] = useState("")
    // const [mobileNumber, setMobileNumber] = useState("")
    // const [state, setState] = useState("")
    // const [description, setDescription] = useState("")
    // const [city, setCity] = useState("")
    // const [pincode, setPincode] = useState("")
    ;
  // { / *******************ADDRESS 1 States End********************/ }

  // { / *******************ADDRESS 2 States Start********************/ }
  const [dealerName2, setDealerName2] = useState("")
  const [doNumber2, setDoNumber2] = useState("")
  const [locationName2, setLocationName2] = useState("")
  const [mobileNumber2, setMobileNumber2] = useState("")
  const [state2, setState2] = useState("")
  const [description2, setDescription2] = useState("")
  const [city2, setCity2] = useState("")
  const [pincode2, setPincode2] = useState("")
  // { / *******************ADDRESS 2 States End********************/ }


  // { / *******************ADDRESS 3 States Start********************/ }
  const [dealerName3, setDealerName3] = useState("")
  const [doNumber3, setDoNumber3] = useState("")
  const [locationName3, setLocationName3] = useState("")
  const [mobileNumber3, setMobileNumber3] = useState("")
  const [state3, setState3] = useState("")
  const [description3, setDescription3] = useState("")
  const [city3, setCity3] = useState("")
  const [pincode3, setPincode3] = useState("")
  // { / *******************ADDRESS 3 States End********************/ }


  // { / *******************ADDRESS 4 States Start********************/ }
  const [dealerName4, setDealerName4] = useState("")
  const [doNumber4, setDoNumber4] = useState("")
  const [locationName4, setLocationName4] = useState("")
  const [mobileNumber4, setMobileNumber4] = useState("")
  const [state4, setState4] = useState("")
  const [description4, setDescription4] = useState("")
  const [city4, setCity4] = useState("")
  const [pincode4, setPincode4] = useState("")
  // { / *******************ADDRESS 4 States End********************/ }

  // { / *******************ADDRESS 5 States Start********************/ }
  const [dealerName5, setDealerName5] = useState("")
  const [doNumber5, setDoNumber5] = useState("")
  const [locationName5, setLocationName5] = useState("")
  const [mobileNumber5, setMobileNumber5] = useState("")
  const [state5, setState5] = useState("")
  const [description5, setDescription5] = useState("")
  const [city5, setCity5] = useState("")
  const [pincode5, setPincode5] = useState("")
  // { / *******************ADDRESS 5 States End********************/ }
  // { / *******************ADDRESS 6 States Start********************/ }
  const [dealerName6, setDealerName6] = useState("")
  const [doNumber6, setDoNumber6] = useState("")
  const [locationName6, setLocationName6] = useState("")
  const [mobileNumber6, setMobileNumber6] = useState("")
  const [state6, setState6] = useState("")
  const [description6, setDescription6] = useState("")
  const [city6, setCity6] = useState("")
  const [pincode6, setPincode6] = useState("")
  // { / *******************ADDRESS 6 States End********************/ }
  const endTimeutcTimestamp = endTime;
  const endTimeistTimestamp = moment();

  useEffect(() => {

    vehicleListData()
    groupListData()



  }, [])
  const calculateMaximumDate = () => {
    setEndTime((prevEndTime) => {
      if (prevEndTime.isAfter(maxTime)) {
        return maxTime;
      }
      return prevEndTime;
    });
  };

  useEffect(() => {
    calculateMaximumDate();
  }, [maxTime]);
  const updateMaxTime = () => {
    setMaxTime(moment(currentDate).add(8, 'hours'));
  };
  useEffect(() => {
    const interval = setInterval(updateMaxTime, 1000); // Update maxTime every second
    return () => clearInterval(interval);
  }, [currentDate]);
  const isValidDate = (current) => {
    return current.isAfter(currentDate, 'day');
  };

  const isValidTime = (current) => {
    const now = moment();
    return current.isSameOrAfter(now, 'minute') && current.isBefore(maxTime);
  };
  // const timeConstraints = {
  //   hours: { min: moment().hour(), max: maxTime.hour(), step: 1 },
  //   minutes: { min: moment().minute(), max: maxTime.minute(), step: 1 },
  //   seconds: { min: moment().second(), max: maxTime.second(), step: 1 },
  //   milliseconds: { min: moment().millisecond(), max: maxTime.millisecond(), step: 100 },
  // };
const handleGroupSelect = (selectedList, selectedItem) => {
  setSelectedGroups(selectedList.map((item) => item.value));
  setGroupError(''); // Clear the groupError when a group is selected
};

  const handleGroupRemove = (selectedList, removedItem) => {
    setSelectedGroups(selectedList.map((item) => item.value));
    if (selectedList.length === 0) {
      setGroupError('Please select at least one group.');
    } else {
      setGroupError(''); // Clear the groupError when a group is removed and at least one group is still selected
    }
  };
 
  const vehicleListData = () => {
    setLoading(true);
    let url = process.env.REACT_APP_BASEURL + 'vehicle/';
    console.log(url)
    const config = {
      headers: {
        "Authorization": `bearer ` + localStorage.getItem('adroit_token'),
        "Access-Control-Allow-Origin": "*",
        "Content-type": "application/json",
      }
    };
    axios.get(url, config)
      .then(res => {
        setVehicleList(res.data.data)
        console.log(res.data.data, "list")

      }
      ).catch(err => {

        console.log(err)
      })
  }
  const handleSubmitBid = (e) => {

    e.preventDefault()

    
    let error = { status: 0, productName: '', resume: '', post: '' }
    if (addData.productName === '') { error.productName = 'Please enter product name'; error.status = 1 } else { error.productName = '' }
    if (addData.weight === '') { error.weight = 'Please enter weight'; error.status = 1 } else { error.weight = '' }
    if (addData.height === '') { error.height = 'Please enter height'; error.status = 1 } else { error.height = '' }
    if (addData.length === '') { error.length = 'Please enter length'; error.status = 1 } else { error.length = '' }
    if (addData.dealerName === '') { error.dealerName = 'Please enter dealer name'; error.status = 1 } else { error.dealerName = '' }
    if (addData.locationName === '') { error.locationName = 'Please enter address'; error.status = 1 } else { error.locationName = '' }
    if (addData.mobileNumber === '') { error.mobileNumber = 'Please enter mobile number'; error.status = 1 } else { error.mobileNumber = '' }
    if (addData.state === '') { error.state = 'Please enter state'; error.status = 1 } else { error.state = '' }
    if (addData.description === '') { error.description = 'Please enter description'; error.status = 1 } else { error.description = '' }
    if (addData.city === '') { error.city = 'Please enter city'; error.status = 1 } else { error.city = '' }
    if (addData.pincode === '') { error.pincode = 'Please enter pincode'; error.status = 1 } else { error.pincode = '' }
    if (addData.doNumber === '') { error.doNumber = 'Please DO number'; error.status = 1 } else { error.doNumber = '' }
    if (addData.length === '') { error.length = 'Please enter length'; error.status = 1 } else { error.length = '' }

    setAddData({ ...addData, productNameerr: error.productName, addresserr: 'Delivery address is required', publicIdErr: 'Vehicle list is required', doNumbererr: error.doNumber, weighterr: error.weight, heighterr: error.height, lengtherr: error.length, dealerNameerr: error.dealerName, locationNameerr: error.locationName, stateerr: error.state, descriptionerr: error.description, cityerr: error.city, pincodeerr: error.pincode, })
    if (selectedGroups.length === 0) {
      setGroupError('Please select at least one group.');
    } else {
      setGroupError(''); // Clear the error message if groups are selected
    }
    if (error.status === 0) {
      setGroupError('Please select at least one group.');
    } else {
      setGroupError(''); // Clear the error message if groups are selected
    }
    if (error.status === 0) {
      if (selectedGroups.length === 0) {
        setGroupError('Please select at least one group.');
        error.group = 'Please select at least one group.';
      } else {
        setGroupError(''); // Clear the groupError if there are selected groups
        error.group = '';
      }

      // If any group error or other errors are present, prevent the API call
      if (error.group || error.status) {
        return;
      }
      setLoadingBid(true)
      const AddressArr = []
      const AddressObj1 = {
        name_of_location: addData.locationName,
        alternate_mobile: addData.mobileNumber,
        pincode: addData.pincode,
        description: addData.description,
        city: addData.city,
        state: addData.state,
        do_number: addData.doNumber,
        dealer_name: addData.dealerName
      }
      const AddressObj2 = {
        name_of_location: locationName2,
        alternate_mobile: mobileNumber2,
        pincode: pincode2,
        description: description2,
        city: city2,
        state: state2,
        do_number: doNumber2,
        dealer_name: dealerName2
      }
      const AddressObj3 = {
        name_of_location: locationName3,
        alternate_mobile: mobileNumber3,
        pincode: pincode3,
        description: description3,
        city: city3,
        state: state3,
        do_number: doNumber3,
        dealer_name: dealerName3
      }
      const AddressObj4 = {
        name_of_location: locationName4,
        alternate_mobile: mobileNumber4,
        pincode: pincode4,
        description: description4,
        city: city4,
        state: state4,
        do_number: doNumber4,
        dealer_name: dealerName4
      }
      const AddressObj5 = {
        name_of_location: locationName5,
        alternate_mobile: mobileNumber5,
        pincode: pincode5,
        description: description5,
        city: city5,
        state: state5,
        do_number: doNumber5,
        dealer_name: dealerName5
      }
      const AddressObj6 = {
        name_of_location: locationName6,
        alternate_mobile: mobileNumber6,
        pincode: pincode6,
        description: description6,
        city: city6,
        state: state6,
        do_number: doNumber6,
        dealer_name: dealerName6
      }
      if (addData.locationName.length > 0) {
        AddressArr.push(AddressObj1)
      }
      if (locationName2.length > 0) {
        AddressArr.push(AddressObj2)
      }
      if (locationName3.length > 0) {
        AddressArr.push(AddressObj3)
      }
      if (locationName4.length > 0) {
        AddressArr.push(AddressObj4)
      }
      if (locationName5.length > 0) {
        AddressArr.push(AddressObj5)
      }
      if (locationName6.length > 0) {
        AddressArr.push(AddressObj6)
      }
      console.log("AddressArr", AddressArr);
    
      const body = {
        location: { latitude: 23.36, longitude: 75.36 },
        truck_arrival_time: truckArrival,
        unloading_points: AddressArr,
        product_name: addData.productName,
        weight: addData.weight,
        vehicle: publicId,
        height: addData.height,
        length: addData.length,
        start_time: startDate,
        end_time: endTime,
        group_id: selectedGroups
      }
   
     

      console.log(body, "body")
      console.log(endTime, "end time")
      let url = process.env.REACT_APP_BASEURL + `bids/?pagination=true`
      console.log(url,'submit')
      const config = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-type": "application/json",
          "Authorization": `bearer ` + localStorage.getItem('adroit_token'),

        }
      };
      console.log("jgjhg")
      // console.log("bodyy", formData)
      axios.post(url, body, config)
        .then(res => {
          setLoadingBid(false)
          setShowPopUps(false);
          getBidList()
          console.log(res.data, "kjhkjh")
          toast.success("Bid Created Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
            theme: "colored",
          })
        
        })
        .catch(err => {
          setLoadingBid(false)
          console.log(err, "errorrr")

          toast.error(`${err.message}`, {
            autoClose: 1000,
            theme: "colored",
          });
        })
    }

  }
// add bid code end

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    if(cardopen === "allbid"){
      getBidDashborad();
      getBidList();
    }else if(cardopen === "ongoingbid"){
      getBidListongoingbid();
    }else if(cardopen === "closedbid"){
      getBidListclosedbid();
    }else if(cardopen === "dispatched"){
      getBidListDispatched();
    }
  }, [cardopen]);
  // ----------------------Dashboard card api----------------------
  const [bidListDash, setBidListDash] = useState([]);
  let navigate =useNavigate()
  const getBidDashborad = () => {
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "application/json",
        "Authorization": `bearer ` + localStorage.getItem('adroit_token'),
      }
    };
    let url = process.env.REACT_APP_BASEURL + "dashboard/"
    axios.get(url, config)
      .then((res) => {
        setBidListDash(res.data.data)
       
      })
      .catch(err => {
        const getErrRes = handleError(err);
        if (getErrRes === 401) {
          toast.error("Something went wrong.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 4000,
            theme: "colored",
          });
        } else {
          toast.error(getErrRes ? getErrRes : "Something went wrong.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 4000,
            theme: "colored",
          });
        }
      }
      )
  }
  // -------------------------close bid api-----------------------------
  const [showPopup, setShowPopUp] = useState(false);
  const [bidList, setBidList] = useState([]);
  const [loading1, setLoading1] = useState(false);
  const getBidListclosedbid = () => {
    setLoading1(true)
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "application/json",
        "Authorization": `bearer ` + localStorage.getItem('adroit_token'),
      }
    };
    let url = process.env.REACT_APP_BASEURL + "bids/?closed_bid=true&pagination=true"
    axios.get(url, config)
      .then((res) => {
        setLoading1(false)
        setBidList(res.data.data)
      })
      .catch(err => {
        setLoading1(false)
        const getErrRes = handleError(err);
        if (getErrRes === 401) {
          toast.error("Something went wrong.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 4000,
            theme: "colored",
          });
        } else {
          toast.error(getErrRes ? getErrRes : "Something went wrong.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 4000,
            theme: "colored",
          });
        }
      }
      )
  }
  // --------------------------close bid popup api---------------------
  const [bidNumber, setBidNumber] = useState("");
  const [bidDataById, setBidDataById] = useState([]);
  const [closedoutAcc, setClosedoutAcc] = useState([]);
  const [closedinAcc, setClosedinAcc] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loading8, setLoading8] = useState(false);
  const [radiochecked, setRadiochecked] = useState();
  const [transporterID, setTransportedID] = useState();
  const getBidDetailsById = (id, bid) => {
    setLoading(true)
    setBidNumber(bid)
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "application/json",
        "Authorization": `bearer ` + localStorage.getItem('adroit_token'),
      }
    };
    let url = process.env.REACT_APP_BASEURL + `superAdmin/bidDetail/${id}/`
    axios.get(url, config)
      .then((res) => {
        setLoading(false)
        if (res.data?.data) {
          setBidDataById(res.data?.data.vendor_bid_bidding)
          setClosedinAcc(res.data?.data.bid_detail.unloading_points)
          setClosedoutAcc(res.data?.data.bid_detail)
          res.data?.data.vendor_bid_bidding.map((el) => {

            const getObj = el.biddings.find((bid) => bid.is_radio_true === "True")
            if (getObj?.is_radio_true === "True") {
              setRadiochecked(getObj?.public_id)
              return

            }
          })

        }
      })
      .catch(err => {
        setLoading(false)
        const getErrRes = handleError(err);
        if (getErrRes === 401) {
          toast.error("Something went wrong.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 4000,
            theme: "colored",
          });
        } else {
          toast.error(getErrRes ? getErrRes : "Something went wrong.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 4000,
            theme: "colored",
          });
        }
      }
      )
  }

  const groupListData = () => {
    setLoading(true);

    let url = process.env.REACT_APP_BASEURL + `publish/group/`
    console.log(url)
    const config = {
      headers: {
        "Authorization": `bearer ` + localStorage.getItem('adroit_token'),
        "Access-Control-Allow-Origin": "*",
        "Content-type": "application/json",
      }
    };
    axios.get(url, config)
      .then(res => {
        setLoading(false)
        setGroupList(res.data.data)
        console.log(res.data, "list")


      }
      ).catch(err => {
        setLoading(false)
        console.log(err)
      })
  }


  const handleDateChange = (date) => {
    // Format the selected date and time in the required format before setting it to the state
    const formattedDate = moment(date).toISOString();
    setEndTime(formattedDate);
    console.log("Formatted Date:", formattedDate);

  };
  const handleDateChange2 = (date) => {
    // Format the selected date and time in the required format before setting it to the state
    const formattedDate = moment(date).toISOString();
    setTruckArrival(formattedDate);
    console.log("Formatted Date:", formattedDate);

  };
  // sakshi code  end
  const closedBid = (data) => {   
    let body = {
      "reason" : data.reason
    }  
    if ( data.reason != '' && radiochecked && transporterID) {
      setLoading8(true)
      let url = process.env.REACT_APP_BASEURL + `biddingAccept/${transporterID}/${radiochecked}/`;
      const config = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-type": "application/json",
          "Authorization": `bearer ` + localStorage.getItem('adroit_token'),
        }
      };
      axios.patch(url, body, config).then((res) => {
        setLoading8(false)
        setShowPopUp(false)
        reset({ reason: "" });
        toast.success("Your bid closed successfully.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 4000,
          theme: "colored",
        });
      }).catch((err) => {
        setLoading8(false)
        const getErrRes = handleError(err);
        if (getErrRes === 401) {
          toast.error("Something went wrong.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 4000,
            theme: "colored",
          });
        } else {
          toast.error(getErrRes ? getErrRes : "Something went wrong.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 4000,
            theme: "colored",
          });
        }
      })
    }
  }
  // ---------------------------all bid api------------------------------
  const [showPopups, setShowPopUps] = useState(false);
  const [bidListDashclosedbid, setBidListDashclosedbid] = useState([]);
  const [loading2, setLoading2] = useState(false);
  const getBidList = () => {
    setLoading2(true)
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "application/json",
        "Authorization": `bearer ` + localStorage.getItem('adroit_token'),
      }
    };
    let url = process.env.REACT_APP_BASEURL + "bids/?pagination=true"
    axios.get(url, config)
      .then((res) => {
        setLoading2(false)
        if (res.data?.data) {
          setBidListDashclosedbid(res.data?.data)
        }
      })
      .catch(err => {
        setLoading2(false)
        const getErrRes = handleError(err);
        if (getErrRes === 401) {
          toast.error("Something went wrong.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 4000,
            theme: "colored",
          });
        } else {
          toast.error(getErrRes ? getErrRes : "Something went wrong.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 4000,
            theme: "colored",
          });
        }
      })
  }
  // ------------------------------all bid popup api----------------------
  const [bidAccordionData, setBidAccordionData] = useState([]);
  const [bidAccordionoutData, setBidAccordionoutData] = useState([]);
  const [loading3, setLoading3] = useState(false);
  const [bidNumbers, setBidNumbers] = useState("");
  const getBidAccordion = (id, bid) => {
    setLoading3(true)
    setBidNumbers(bid)
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "application/json",
        "Authorization": `bearer ` + localStorage.getItem('adroit_token'),
      }
    };
    let url = process.env.REACT_APP_BASEURL + `bid/${id}/`
    console.log(url,"id")
    axios.get(url, config)
      .then((res) => {
        setLoading3(false)
        setPublicId(res.data.data.required_vehicle_public_id)
        let newData = res.data.data.group_name.map((item) => item.id);
          setBidAccordionData(res.data.data.unloading_points)
       
        setGroupName(res.data.data.group_name)
        setSelectedGroups(newData)
          setBidAccordionoutData(res.data.data)
          // setGroupList(res.data.data.group_name)
          setAddData({
            productName: res.data.data.product_name,
            weight:res.data.data.weight,
            height: res.data.data.height,
            length: res.data.data.length,
            dealerName: res.data.data.unloading_points[0].dealer_name,
            doNumber: res.data.data.unloading_points[0].do_number,
            locationName: res.data.data.unloading_points[0].name_of_location,
            city: res.data.data.unloading_points[0].city,
            pincode: res.data.data.unloading_points[0].pincode,
            mobileNumber: res.data.data.unloading_points[0].alternate_mobile,
            description: res.data.data.unloading_points[0].description,
            state: res.data.data.unloading_points[0].state,
          
            

          })
        if (res.data.data.unloading_points[1]){          
        setDealerName2(res.data.data.unloading_points[1].dealer_name)
          setDoNumber2(res.data.data.unloading_points[1].do_number)
          setLocationName2(res.data.data.unloading_points[1].name_of_location)
          setCity2(res.data.data.unloading_points[1].city)
          setPincode2(res.data.data.unloading_points[1].pincode)
          setMobileNumber2(res.data.data.unloading_points[1].alternate_mobile)
          setDescription2(res.data.data.unloading_points[1].description)
          setState2(res.data.data.unloading_points[1].state)
      }
        if (res.data.data.unloading_points[2]){
          setDealerName3(res.data.data.unloading_points[2].dealer_name)
          setDoNumber3(res.data.data.unloading_points[2].do_number)
          setLocationName3(res.data.data.unloading_points[2].name_of_location)
          setCity3(res.data.data.unloading_points[2].city)
          setPincode3(res.data.data.unloading_points[2].pincode)
          setMobileNumber3(res.data.data.unloading_points[2].alternate_mobile)
          setDescription3(res.data.data.unloading_points[2].description)
          setState3(res.data.data.unloading_points[2].state)
        } 
         if (res.data.data.unloading_points[3]){
          setDealerName4(res.data.data.unloading_points[3].dealer_name)
          setDoNumber4(res.data.data.unloading_points[3].do_number)
          setLocationName4(res.data.data.unloading_points[3].name_of_location)
          setCity4(res.data.data.unloading_points[3].city)
          setPincode4(res.data.data.unloading_points[3].pincode)
          setMobileNumber4(res.data.data.unloading_points[3].alternate_mobile)
          setDescription4(res.data.data.unloading_points[3].description)
           setState4(res.data.data.unloading_points[3].state)
        } 
        if (res.data.data.unloading_points[4]) {
          setDealerName5(res.data.data.unloading_points[4].dealer_name)
          setDoNumber5(res.data.data.unloading_points[4].do_number)
          setLocationName5(res.data.data.unloading_points[4].name_of_location)
          setCity5(res.data.data.unloading_points[4].city)
          setPincode5(res.data.data.unloading_points[4].pincode)
          setMobileNumber5(res.data.data.unloading_points[4].alternate_mobile)
          setDescription5(res.data.data.unloading_points[4].description)
          setState5(res.data.data.unloading_points[4].state)
        } 
        if (res.data.data.unloading_points[5]) {
          setDealerName6(res.data.data.unloading_points[5].dealer_name)
          setDoNumber6(res.data.data.unloading_points[5].do_number)
          setLocationName6(res.data.data.unloading_points[5].name_of_location)
          setCity6(res.data.data.unloading_points[5].city)
          setPincode6(res.data.data.unloading_points[5].pincode)
          setMobileNumber6(res.data.data.unloading_points[5].alternate_mobile)
          setDescription6(res.data.data.unloading_points[5].description)
          setState6(res.data.data.unloading_points[5].state)
        }
      
      })
      
      .catch(err => {
        setLoading3(false)
        const getErrRes = handleError(err);
        if (getErrRes === 401) {
          toast.error("Something went wrong.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 4000,
            theme: "colored",
          });
        } else {
          toast.error(getErrRes ? getErrRes : "Something went wrong.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 4000,
            theme: "colored",
          });
        }
      }
      )
  }
  // -------------------------------ongoing bid api-----------------------
  const [bidListDashongoingbid, setBidListDashongoingbid] = useState([]);
  const [loading4, setLoading4] = useState(false);
  const [showPopups2, setShowPopUps2] = useState(false);
  const getBidListongoingbid = () => {
    setLoading4(true)
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "application/json",
        "Authorization": `bearer ` + localStorage.getItem('adroit_token'),
      }
    };
    let url = process.env.REACT_APP_BASEURL + "bids/?on_going=true/"
    axios.get(url, config)
      .then((res) => {
        setLoading4(false)
        if (res.data?.data) {
          setBidListDashongoingbid(res.data?.data)
        }
      })
      .catch(err => {
        setLoading4(false)
        const getErrRes = handleError(err);
        if (getErrRes === 401) {
          toast.error("Something went wrong.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 4000,
            theme: "colored",
          });
        } else {
          toast.error(getErrRes ? getErrRes : "Something went wrong.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 4000,
            theme: "colored",
          });
        }
      })
  }
  // ------------------------ongoing bid popup apis-----------------------
  const [bidAccordionongoingData, setBidAccordionongoingData] = useState([]);
  const [bidAccordionongoingoutData, setBidAccordionongoingoutData] = useState([]);
  const [loading7, setLoading7] = useState(false);
  const [bidNumbers4, setBidNumbers4] = useState("");
  const getBidOngoingbid = (id, bid) => {
    setLoading7(true)
    setBidNumbers4(bid)
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "application/json",
        "Authorization": `bearer ` + localStorage.getItem('adroit_token'),
      }
    };
    let url = process.env.REACT_APP_BASEURL + `bid/${id}/`
    axios.get(url, config)
      .then((res) => {
        setLoading7(false)
        if (res.data?.data) {
          setBidAccordionongoingData(res.data.data?.unloading_points)
          setBidAccordionongoingoutData(res.data.data)
        }
      })
      .catch(err => {
        setLoading7(false)
        const getErrRes = handleError(err);
        if (getErrRes === 401) {
          toast.error("Something went wrong.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 4000,
            theme: "colored",
          });
        } else {
          toast.error(getErrRes ? getErrRes : "Something went wrong.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 4000,
            theme: "colored",
          });
        }
      }
      )
  }
  const [tname, setTname] = useState([]);
  const [showC, setShowC] = useState(false);
  const getBidOngoingTname = (id) => {
    if (showC === false) {
      const config = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-type": "application/json",
          "Authorization": `bearer ` + localStorage.getItem('adroit_token'),
        }
      };
      let url = process.env.REACT_APP_BASEURL + `bid/vendors/${id}/`
      axios.get(url, config)
        .then((res) => {
          if (res.data?.data) {
            setTname(res.data.data)
          }
        })
        .catch(err => {
          const getErrRes = handleError(err);
        if (getErrRes === 401) {
          toast.error("Something went wrong.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 4000,
            theme: "colored",
          });
        } else {
          toast.error(getErrRes ? getErrRes : "Something went wrong.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 4000,
            theme: "colored",
          });
        }
        }
        )
    }
  }
  // -----------------------dispatched bid api---------------------------
  const [bidListDispatchedbid, setBidListDispatchedbid] = useState([]);
  const [loading5, setLoading5] = useState(false);
  const [showPopups3, setShowPopUps3] = useState(false);
  const getBidListDispatched = () => {
    setLoading5(true)
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "application/json",
        "Authorization": `bearer ` + localStorage.getItem('adroit_token'),
      }
    };
    let url = process.env.REACT_APP_BASEURL + "bids/?dispatch=true/"
    axios.get(url, config)
      .then((res) => {
        setLoading5(false)
        if (res.data?.data) {
          setBidListDispatchedbid(res.data?.data)
        }
      })
      .catch(err => {
        setLoading5(false)
        const getErrRes = handleError(err);
        if (getErrRes === 401) {
          toast.error("Something went wrong.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 4000,
            theme: "colored",
          });
        } else {
          toast.error(getErrRes ? getErrRes : "Something went wrong.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 4000,
            theme: "colored",
          });
        }
      })
  }
  // ------------------------dispatched bid popup api----------------------
  const [bidDispatchedData, setBidDispatchedData] = useState([]);
  const [bidDispatchedoutData, setBidDispatchedoutData] = useState([]);
  const [bidvehicleData, setBidvehicleData] = useState([]);
  const [loading6, setLoading6] = useState(false)
  const [bidNumbers3, setBidNumbers3] = useState("")
  const getBidDispatchedById = (id, bid) => {
    setLoading6(true)
    setBidNumbers3(bid)
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "application/json",
        "Authorization": `bearer ` + localStorage.getItem('adroit_token'),
      }
    };
    let url = process.env.REACT_APP_BASEURL + `bid/${id}/`
    axios.get(url, config)
      .then((res) => {
        setLoading6(false)
        if (res.data?.data) {
          setBidDispatchedData(res.data.data?.unloading_points)
          setBidDispatchedoutData(res.data.data)
          setBidvehicleData(res.data.data?.vehicle)
        }
      })
      .catch(err => {
        setLoading6(false)
        const getErrRes = handleError(err);
        if (getErrRes === 401) {
          toast.error("Something went wrong.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 4000,
            theme: "colored",
          });
        } else {
          toast.error(getErrRes ? getErrRes : "Something went wrong.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 4000,
            theme: "colored",
          });
        }
      }
      )
  }
  return (
    <>
      <div className='container-fluid mt-4'>
        <div className='row'>
          <div className='col'>
            <CardGroup>
              <Card onClick={() => {
                setCardOpen("allbid"); setIsActive(1)
              }} className={isActive === 1 ?'m-2 rounded shadow btn Actcard':'m-2 rounded border-0 shadow btn'} style={{ background: "linear-gradient(180deg, #FFF6EF 0%, #FFFFFF 100%)", cursor: 'pointer' }}>
                <Card.Body className='d-flex justify-content-between'>
                  <span className='text-start'>
                    <Card.Title className='fw-normal  '  >All Bids</Card.Title>
                    <Card.Text className='fs-3' style={{ color: "#D66E23" }}>{bidListDash.all_bids ? bidListDash.all_bids : "0"}</Card.Text>
                  </span>
                  <span className='text-end'>
                    <img
                      src={first}
                      className="img-fluid mx-1 mt-1 mb-0"
                      style={{ width: 36, height: 36 }}
                      alt=""
                    />
                  </span>
                </Card.Body>
              </Card>
              <Card onClick={() => {
                setCardOpen("ongoingbid");setIsActive(2)
              }} className={isActive === 2 ?'m-2 rounded shadow btn Actcard':'m-2 rounded border-0 shadow btn'} style={{ background: "linear-gradient(180deg, #FFFCF0 0%, #FFFFFF 100%)", cursor: 'pointer' }}>
                <Card.Body className='d-flex justify-content-between'>
                  <span className='text-start'>
                    <Card.Title className='fw-normal' >On Going Bid</Card.Title>
                    <Card.Text className='fs-3' style={{ color: "#CDAE33" }}>{bidListDash.on_going_bids ? bidListDash.on_going_bids : "0"}</Card.Text>
                  </span>
                  <span className='text-end'>
                    <img
                      src={second}
                      className="img-fluid mx-1 mt-1 mb-0"
                      style={{ width: 36, height: 36 }}
                      alt=""
                    />
                  </span>
                </Card.Body>
              </Card>
              <Card onClick={() => {
                setCardOpen("closedbid");setIsActive(3)
              }} className={isActive === 3 ?'m-2 rounded shadow btn Actcard':'m-2 rounded border-0 shadow btn'} style={{ background: "linear-gradient(180deg, #FCF4FF 0%, #FFFFFF 100%)", cursor: 'pointer' }}>
                <Card.Body className='d-flex justify-content-between'>
                  <span className='text-start'>
                    <Card.Title className='fw-normal'>Closed Bid</Card.Title>
                    <Card.Text className='fs-3' style={{ color: "#A94ACB" }}>{bidListDash.closed_bids ? bidListDash.closed_bids : "0"}</Card.Text>
                  </span>
                  <span className='text-end'>
                    <img
                      src={third}
                      className="img-fluid mx-1 mt-1 mb-0"
                      style={{ width: 36, height: 36 }}
                      alt=""
                    />
                  </span>
                </Card.Body>
              </Card>
              <Card onClick={() => {
                setCardOpen("finalhours");setIsActive(4)
              }} className={isActive === 4 ?'m-2 rounded shadow btn Actcard':'m-2 rounded border-0 shadow btn'} style={{ background: "linear-gradient(180deg, #FCFFF3 0%, #FFFFFF 100%)", cursor: 'pointer' }}>
                <Card.Body className='d-flex justify-content-between'>
                  <span className='text-start'>
                    <Card.Title className='fw-normal'>Final Hours</Card.Title>
                    <Card.Text className='fs-3' style={{ color: "#758D36" }}>0
                    {/* {bidListDash.final_hours ? bidListDash.final_hours : "0"} */}
                    </Card.Text>
                  </span>
                  <span className='text-end'>
                    <img
                      src={fifth}
                      className="img-fluid mx-1 mt-1 mb-0"
                      style={{ width: 36, height: 36 }}
                      alt=""
                    />
                  </span>
                </Card.Body>
              </Card>
              <Card onClick={() => {
                setCardOpen("dispatched");setIsActive(5)
              }} className={isActive === 5 ?'m-2 rounded shadow btn Actcard':'m-2 rounded border-0 shadow btn'} style={{ background: "linear-gradient(180deg, #FFEDEF 0%, #FFF7F7 0.01%, #FFFFFF 100%)", cursor: 'pointer' }}>
                <Card.Body className='d-flex justify-content-between'>
                  <span className='text-start'>
                    <Card.Title className='fw-normal'>Dispatched</Card.Title>
                    <Card.Text className='fs-3' style={{ color: "#B94B56" }}>{bidListDash.dispatched ? bidListDash.dispatched : "0"}</Card.Text>
                  </span>
                  <span className='text-end'>
                    <img
                      src={sixth}
                      className="img-fluid mx-1 mt-1 mb-0"
                      style={{ width: 36, height: 36 }}
                      alt=""
                    />
                  </span>
                </Card.Body>
              </Card>
              <Card onClick={() => {
                setCardOpen("deliverystarted");setIsActive(6)
              }} className={isActive === 6 ?'m-2 rounded shadow btn Actcard':'m-2 rounded border-0 shadow btn'} style={{ background: "linear-gradient(180deg, #F2FFFF 0%, #FFFFFF 100%)", cursor: 'pointer' }}>
                <Card.Body className='d-flex justify-content-between'>
                  <span className='text-start'>
                    <Card.Title className='fw-normal'>Delivery Started</Card.Title>
                    <Card.Text className='fs-3' style={{ color: "#4EA7A9" }}>{bidListDash.delivery_start ? bidListDash.delivery_start : "0"}</Card.Text>
                  </span>
                  <span className='text-end'>
                    <img
                      src={forth}
                      className="img-fluid mx-1 mt-1 mb-0"
                      style={{ width: 36, height: 36 }}
                      alt=""
                    />
                  </span>
                </Card.Body>
              </Card>
            </CardGroup>
          </div>
        </div>
        {
          cardopen == "allbid" ? <>
            <div className='row mt-2 mb-5'>
              <span className='d-flex justify-content-between'>
                <span><h3 className='p-3'>All Bid</h3></span>
                <span className='pt-3 pe-3'><button onClick={() => { navigate('/addBid') }} className='btn text-white BTN_Color'>Add Bid</button></span>
              </span>

              <div className='col'>
                <Card className='rounded-4 shadow border-0'>
                  <div className='table-responsive my-3'>
                    <table className="table">
                      <tbody>
                        {loading2 ? <Tableskeleton /> :
                        bidListDashclosedbid.length > 0 && bidListDashclosedbid.map((item, index) => (
                          <tr className='align-middle' key={index}>
                            <th className='align-middle p-4' >Bid ID #{item.bid_number} <div className='fw-normal fs-6'>Product:- {item.product_name}</div></th>
                            <th className='align-middle p-4' >Delivery Points<div className='fw-normal fs-6'>{item.unloading_points}</div></th>
                            <th className='align-middle p-4' >Arrival Cut Off<div className='fw-normal fs-6'>{moment(item.truck_arrival_time).format("DD-MMMM-YYYY, LT")}</div></th>
                            <th className='align-middle p-4' >Bid Closes In<div className='fw-normal fs-6'><CountdownTimer initialTime={item.remain_time}/></div></th>
                            <th className='align-middle p-2' >Vehicle Type
                              <div className='fw-normal fs-6'><span className='mt-3'>{item.required_vehicle_name} </span><br />
                                <div className='pt-2'>
                                  <span className='bg-light text-black fs-6 rounded p-1 border border-1 m-1'>L-{item.length}</span>
                                  <span className='bg-light text-black fs-6 rounded p-1 border border-1 m-1'>W-{item.weight}</span>
                                  <span className='bg-light text-black fs-6 rounded p-1 border border-1 m-1'>H-{item.height}</span>
                                </div>
                              </div>
                            </th>
                            <th className='align-middle p-3' style={{tableLayout:'fixed'}}><span className='mt-3 row'>
                              <span className='col-xl-6 col-lg-12 col-md-12 col-sm-12'>
                                {item.required_vehicle_image === null || '' ? <span style={{ color: 'gray' }}>No Image Found</span> : <img
                                  src={item.required_vehicle_image}
                                  className="img-fluid mx-1 mt-1 mb-0"
                                  style={{ width: "150px", height: "60px" }}
                                  alt=""
                                />
}</span>
                              <span className='col-xl-6 col-lg-12 col-md-12 col-sm-12 pt-3'><button onClick={() => {
                                setShowPopUps(true);
                                getBidAccordion(item.public_id, item.bid_number)
                              }} className='btn px-3 text-white BTN_Color'>View</button></span>
                            </span></th>
                          </tr>
                        ))}
                        {!loading2 && bidListDashclosedbid?.length === 0 && (
                          <Card className="border-0 ">
                            <Card.Body>
                              <h6 className="align-middle text-center fs-3 my-5 py-5">
                                No Data Found
                              </h6>
                            </Card.Body>
                          </Card>
                        )}
                      </tbody>
                    </table>
                  </div>
                </Card>
              </div>
            </div>
          </> : cardopen == "ongoingbid" ? <>
            <div className='row mt-2 mb-5'>
              <h3 className='p-3'>On Going Bid</h3>
              <div className='col'>
                <Card className='rounded-4 shadow border-0'>
                  <div className='table-responsive my-3'>
                    <table className="table">
                      <tbody>
                        {loading4 ? <Tableskeleton /> :
                        bidListDashongoingbid.length > 0 && bidListDashongoingbid.map((item, index) => (
                          <tr className='align-middle' key={index}>
                            <th className='align-middle p-4'>Bid ID #{item.bid_number}<div className='fw-normal fs-6'>Product: {item.product_name}</div></th>
                            <th className='align-middle p-4'>Delivery Points<div className='fw-normal fs-6'>{item.unloading_points}</div></th>
                            <th className='align-middle p-4'>Arrival Cut Off<div className='fw-normal fs-6'>{moment(item.truck_arrival_time).format("DD-MMMM-YYYY, LT")}</div></th>
                            <th className='align-middle p-4'>Bid Closes In<div className='fw-normal fs-6'><CountdownTimer initialTime={item.remain_time} /></div></th>
                            <th className='align-middle p-2' >Vehicle Type
                              <div className='fw-normal fs-6'><span className='mt-3'>{item.required_vehicle_name}</span><br />
                                <div className='pt-2'>
                                  <span className='bg-light text-black fs-6 rounded p-1 border border-1 m-1'>L-{item.length}</span>
                                  <span className='bg-light text-black fs-6 rounded p-1 border border-1 m-1'>W-{item.weight}</span>
                                  <span className='bg-light text-black fs-6 rounded p-1 border border-1 m-1'>H-{item.height}</span>
                                </div>
                              </div>
                            </th>
                            <th className='align-middle p-4'><span className='mt-3 row'>
                              <span className='col-xl-6 col-lg-12 col-md-12 col-sm-12'>
                                {item.required_vehicle_image === null || '' ? <span style={{ color: 'gray' }}>No Image Found</span> :
                                  <img
                                    src={item.required_vehicle_image}
                                    className="img-fluid mx-1 mt-1 mb-0"
                                    style={{ width: "150px", height: "60px" }}
                                    alt=""
                                  />}
                              </span>
                              <span className='col-xl-6 col-lg-12 col-md-12 col-sm-12 pt-3'><button
                                onClick={() => {
                                  setShowPopUps2(true);
                                  getBidOngoingbid(item.public_id, item.bid_number)
                                }}
                                className='btn px-3 text-white BTN_Color'>View</button></span>
                            </span></th>
                          </tr>
                        ))}
                        {!loading4 && bidListDashongoingbid?.length === 0 && (
                          <Card className="border-0 ">
                            <Card.Body>
                              <h6 className="align-middle text-center fs-3 my-5 py-5">
                                No Data Found
                              </h6>
                            </Card.Body>
                          </Card>
                        )}
                      </tbody>
                    </table>
                  </div>
                </Card>
              </div>
            </div>
          </> : cardopen == "closedbid" ? <>
            <div className='row mt-2 mb-5'>
              <h3 className='p-3'>Closed Bid</h3>
              <div className='col'>
                <Card className='rounded-4 shadow border-0'>
                  <div className='table-responsive my-3'>
                    <table className="table">
                      <tbody>
                        {loading1 ? <Tableskeleton /> :
                        bidList.length > 0 && bidList.map((item, index) => (
                          <tr className='align-middle' key={index}>
                            <th className='align-middle p-4'>Bid ID #{item.bid_number} <div className='fw-normal fs-6'>Product:- {item.product_name}</div></th>
                            <th className='align-middle p-4'>Delivery Points<div className='fw-normal fs-6'>{item.unloading_points}</div></th>
                            <th className='align-middle p-4'>Arrival Cut Off<div className='fw-normal fs-6'>{moment(item.truck_arrival_time).format("DD-MMMM-YYYY, LT")}</div></th>
                            <th className='align-middle p-4'>Bid Closed<div className='fw-normal fs-6'><CountDownPlus initialTime={item.remain_time} /></div></th>
                            <th className='align-middle p-2' >Vehicle Type
                              <div className='fw-normal fs-6'><span className='mt-3'>{item.required_vehicle_name} </span><br />
                                <div className='pt-2'>
                                  <span className='bg-light text-black fs-6 rounded p-1 border border-1 m-1'>L-{item.length}</span>
                                  <span className='bg-light text-black fs-6 rounded p-1 border border-1 m-1'>W-{item.weight}</span>
                                  <span className='bg-light text-black fs-6 rounded p-1 border border-1 m-1'>H-{item.height}</span>
                                </div>
                              </div>
                            </th>
                            <th className='align-middle p-4'><span className='mt-3 row'>
                              <span className='col-xl-6 col-lg-12 col-md-12 col-sm-12'>{item.required_vehicle_image === null || '' ? <span style={{ color: 'gray' }}>No Image Found</span> : 
                                <img
                                  src={item.required_vehicle_image}
                                  className="img-fluid mx-1 mt-1 mb-0"
                                  style={{ width: "150px", height: "60px" }}
                                  alt=""
                                />}</span>
                              <span className='col-xl-6 col-lg-12 col-md-12 col-sm-12 pt-3'><button onClick={() => {
                                setShowPopUp(true);
                                getBidDetailsById(item.public_id, item.bid_number)
                              }} className='btn px-3 text-white BTN_Color'>View</button></span>
                            </span></th>
                          </tr>
                        ))}
                        {!loading1 && bidList?.length === 0 && (
                          <Card className="border-0 ">
                            <Card.Body>
                              <h6 className="align-middle text-center fs-3 my-5 py-5">
                                No Data Found
                              </h6>
                            </Card.Body>
                          </Card>
                        )}
                      </tbody>
                    </table>
                  </div>
                </Card>
              </div>
            </div>
          </> : cardopen == "finalhours" ? <>
            <div className='row mt-2 mb-5'>
              <h3 className='p-3'>Final Hours</h3>
              <div className='col'>
                <Card className='rounded-4 shadow border-0'>
                  {/* <div className='table-responsive my-3'>
                    <table className="table">
                      <tbody>
                        <tr className='align-middle'>
                          <th className='align-middle p-4'>Bid ID #426589<div className='fw-normal fs-6'>Product: Dinner set</div></th>
                          <th className='align-middle p-4'>Delivery Points<div className='fw-normal fs-6'></div>1</th>
                          <th className='align-middle p-4'>Arrival Cut Off<div className='fw-normal fs-6'></div>2023-06-10T11:01:00+05:30</th>
                          <th className='align-middle p-4'>Bid Closes In<div className='fw-normal fs-6'></div>-14 days, 23:18:47</th>
                          <th className='align-start p-4' style={{ width: "320px" }}>Vehicle Type
                            <div className='fw-normal fs-6'><span className='mt-3'>TATA TRUCK (6 TYRE)</span><br />
                              <div className='pt-2'>
                                <span className='bg-light text-black fs-6 rounded p-1 border border-1 m-1'>L-20</span>
                                <span className='bg-light text-black fs-6 rounded p-1 border border-1 m-1'>W-5</span>
                                <span className='bg-light text-black fs-6 rounded p-1 border border-1 m-1'>H-10</span>
                              </div>
                            </div>
                          </th>
                          <th className='align-middle p-4'><span className='mt-3 row'>
                            <span className='col-xl-6 col-lg-12 col-md-12 col-sm-12'><img
                              src={track}
                              className="img-fluid mx-1 mt-1 mb-0"
                              style={{ width: "90%", height: "80%" }}
                              alt=""
                            /></span>
                            <span className='col-xl-6 col-lg-12 col-md-12 col-sm-12 pt-3'><button className='btn px-3 text-white' style={{ backgroundColor: "#8D6BA6" }}>View</button></span>
                          </span></th>
                        </tr>
                      </tbody>
                    </table>
                  </div> */}
                  <Card className="border-0 ">
                  <Card.Body>
                    <h6 className="align-middle text-center fs-3 my-5 py-5">
                      No Data Found
                    </h6>
                  </Card.Body>
                </Card>
                </Card>
              </div>
            </div>
          </> : cardopen == "dispatched" ? <>
            <div className='row mt-2 mb-5'>
              <h3 className='p-3'>Dispatched</h3>
              <div className='col'>
                <Card className='rounded-4 shadow border-0'>
                  <div className='table-responsive my-3'>
                    <table className="table">
                      <tbody>
                        {loading5 ? <Tableskeleton /> :
                        bidListDispatchedbid.length > 0 && bidListDispatchedbid.map((item, index) => {
                          return(
                            <tr className='align-middle' key={index}>
                              <th className='align-middle p-0'>
                                <span className='row'><span className='col-12'>
                                  {/* <img
                                src={track}
                                className="img-fluid mx-1 mt-4 mb-0"
                                style={{ width: "70%", height: "60%" }}
                                alt=""
                              /> */}
                                  {
                                    item.required_vehicle_image.length > 39
                                      ?
                                      <img
                                        src={item.required_vehicle_image}
                                        className="img-fluid m-2 ms-3"
                                        style={{ width: "110px", height: "70px" }}
                                        alt=""
                                      />
                                      :
                                      <p className='small text-center fw-normal'>Image Not Available</p>
                                  }
                                </span></span>
                              </th>
                              <th className='align-middle p-0'>Bid ID #<span className='fw-normal'>{item.bid_number}</span></th>
                              <th className='align-middle p-0'>Product:- <span className='fw-normal'>{item.product_name}</span></th>
                              <th className='align-middle p-0'>Delivery Points :- <span className='fw-normal'>{item.unloading_points}</span></th>
                              <th className='align-middle p-0'>Bid Closed :- <span className='fw-normal'>{item.remain_time ? item.remain_time.split("-") : item.remain_time}</span></th>
                              <th className='align-middle p-0'> <span className='pt-3'><button onClick={() => {
                                setShowPopUps3(true);
                                getBidDispatchedById(item.public_id, item.bid_number)
                              }} className='btn px-3 text-white BTN_Color'>View</button></span> </th>
                            </tr>

                        )})}
                        {!loading5 && bidListDispatchedbid?.length === 0 && (
                          <Card className="border-0 ">
                            <Card.Body>
                              <h6 className="align-middle text-center fs-3 my-5 py-5">
                                No Data Found
                              </h6>
                            </Card.Body>
                          </Card>
                        )}
                      </tbody>
                    </table>
                  </div>
                </Card>
              </div>
            </div>
          </> : cardopen == "deliverystarted" ? <>
            <div className='row mt-2 mb-5'>
              <h3 className='p-3'>Delivery Started</h3>
              <div className='col'>
                <Card className='rounded-4 shadow border-0'>
                  {/* <div className='table-responsive my-3'>
                    <table className="table" >
                      <tbody>
                        <tr className='align-middle'>
                          <th className='align-middle p-4'>Bid ID #426589<div className='fw-normal fs-6'>Product: Dinner set</div></th>
                          <th className='align-middle p-4'>Delivery Points<div className='fw-normal fs-6'></div>1</th>
                          <th className='align-middle p-4'>Arrival Cut Off<div className='fw-normal fs-6'></div>2023-06-10T11:01:00+05:30</th>
                          <th className='align-middle p-4'>Bid Closes In<div className='fw-normal fs-6'></div>-14 days, 23:18:47</th>
                          <th className='align-start p-4' style={{ width: "320px" }}>Vehicle Type
                            <div className='fw-normal fs-6'><span className='mt-3'>TATA TRUCK (6 TYRE)</span><br />
                              <div className='pt-2'>
                                <span className='bg-light text-black fs-6 rounded p-1 border border-1 m-1'>L-20</span>
                                <span className='bg-light text-black fs-6 rounded p-1 border border-1 m-1'>W-5</span>
                                <span className='bg-light text-black fs-6 rounded p-1 border border-1 m-1'>H-10</span>
                              </div>
                            </div>
                          </th>
                          <th className='align-middle p-4'><span className='mt-3 row'>
                            <span className='col-xl-6 col-lg-12 col-md-12 col-sm-12'><img
                              src={track}
                              className="img-fluid mx-1 mt-1 mb-0"
                              style={{ width: "90%", height: " 80%" }}
                              alt=""
                            /></span>
                            <span className='col-xl-6 col-lg-12 col-md-12 col-sm-12 pt-3'><button className='btn px-3 text-white' style={{ backgroundColor: "#8D6BA6" }}>View</button></span>
                          </span></th>
                        </tr>
                      </tbody>
                    </table>
                  </div> */}
                  <Card className="border-0 ">
                  <Card.Body>
                    <h6 className="align-middle text-center fs-3 my-5 py-5">
                      No Data Found
                    </h6>
                  </Card.Body>
                </Card>
                </Card>
              </div>
            </div>
          </> : null
        }
        {/* --------------------------------Closed Bid modal--------------------------------- */}
        <Modal show={showPopup} onHide={()=>{setShowPopUp(false)}} size="xl" scrollable>
          <Modal.Header closeButton style={{ backgroundColor: "#1AA4C8" }} >
            <Modal.Title className='text-white'>
              Bid ID #{bidNumber ? bidNumber : "Not Available"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <div className='row my-3 '>
              <div className='col-6'>
                <p><span className='fw-semibold'>Product :- </span>{closedoutAcc.product_name ? closedoutAcc.product_name : "Not Available"}</p>
                <p><span className='fw-semibold'>Truck Arrival Cut Off :- </span>{moment(closedoutAcc.truck_arrival_time).format("DD-MMMM-YYYY, LT") ? moment(closedoutAcc.truck_arrival_time).format("DD-MMMM-YYYY, LT") : "Not Available"}</p>
                <p><span className='fw-semibold'>Weight :- </span>{closedoutAcc.weight ? closedoutAcc.weight : "Not Available"} {closedoutAcc.weight_unit}</p>
                <p><span className='fw-semibold'>Vehicle Type :- </span>{closedoutAcc.required_vehicle_name ? closedoutAcc.required_vehicle_name : "Not Available"}</p>
                {/* <p><span className='fw-semibold'>Vehicle Max Load :- </span>{closedoutAcc.required_vehicle_max_load ? closedoutAcc.required_vehicle_max_load : "Not Available"}</p> */}
                <p><span className='fw-semibold'>Bid Closes In :- </span>{closedoutAcc.remain_time ? (closedoutAcc.remain_time).split("-") : "Not Available"}</p>
              </div>
              <div className='col-6'>
                <p><span className='fw-semibold'>Height :- </span>{closedoutAcc.height ? closedoutAcc.height : "Not Available"} {closedoutAcc.height_unit}</p>
                <p><span className='fw-semibold'>Length :- </span>{closedoutAcc.length ? closedoutAcc.length : "Not Available"} {closedoutAcc.length_unit}</p>
                <p><span className='fw-semibold'>Start Time :- </span>{moment(closedoutAcc.start_time).format("DD-MMMM-YYYY, LT") ? moment(closedoutAcc.start_time).format("DD-MMMM-YYYY, LT") : "Not Available"}</p>
                <p><span className='fw-semibold'>End Time :- </span>{moment(closedoutAcc.end_time).format("DD-MMMM-YYYY, LT") ? moment(closedoutAcc.end_time).format("DD-MMMM-YYYY, LT") : "Not Available"}</p>
                {/* <p><span className='fw-semibold'>Expected Deliver Time :- </span>{moment(closedoutAcc.expected_deliver_time).format("DD-MMMM-YYYY, LT") ? moment(closedoutAcc.expected_deliver_time).format("DD-MMMM-YYYY, LT") : "Not Available"}</p> */}
              </div>
              
            </div>
            <div className='row mt-3 mb-3'>
              <div className='col'>
                <div className='table-responsive'>
                  <span className='small fw-semibold ms-2 mb-1'>Delivery Points</span>
                  <Accordion>
                    {closedinAcc.length > 0 && closedinAcc.map((item, index) => {
                      return(
                      <Accordion.Item eventKey={item.public_id} className='mb-3'>
                        <Accordion.Header >Unloading Points {index + 1}</Accordion.Header>
                        <Accordion.Body>
                          <p><span className='fw-semibold'>Dealer Name :- </span>{item.dealer_name ? item.dealer_name : "Not Available"}</p>
                          {/* <p><span className='fw-semibold'>Landmark :- </span>{item.landmark ? item.landmark : "Not Available"}</p> */}
                          <p><span className='fw-semibold'>Location :- </span>{item.name_of_location ? item.name_of_location : "Not Available"}</p>
                          <p><span className='fw-semibold'>City :- </span>{item.city ? item.city : "Not Available"}</p>
                          <p><span className='fw-semibold'>Pincode :- </span>{item.pincode ? item.pincode : "Not Available"}</p>
                          <p><span className='fw-semibold'>Mobile No. :- </span>{item.alternate_mobile ? item.alternate_mobile : "Not Available"}</p>
                          <p><span className='fw-semibold'>Description :- </span>{item.description ? item.description : "Not Available"}</p>
                        </Accordion.Body>
                      </Accordion.Item>
                    )})}
                  </Accordion>
                </div>
              </div>
            </div>
            <div className='row mt-3 mb-3'>
              <div className='col'>
                <div className='table-container'>
                  <table className="table responsive-table" >
                    <thead>
                      <tr className='align-middle'>
                        <th className='bg-light p-2'>Transporter Name</th>
                        {/* <td className='bg-light p-2 small'>Bid @ 25% Advance And Remaining immediate .....</td> */}
                        {/* <td className='bg-light p-2 small'>Complete Amount @ 7 Days After Delivery</td> */}
                        <td className='bg-light p-2 small'>Complete Amount In 15 Days</td>
                        <td className='bg-light p-2 small'>Complete Amount In 30 Days</td>
                        <td className='bg-light p-2 small'>Complete Amount In 60 Days</td>
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? <Tableskeleton /> :
                        bidDataById?.length > 0 && bidDataById.map((item, index) => {
                          return (
                            <tr className='align-middle' key={index}>
                              <th className='align-middle p-3'>{item.transporter_name ? item.transporter_name : "Not Available"}</th>
                              {item.biddings.map((innerdata, index1) => {
                                return (
                                  <td className='align-middle p-1' key={index1}>
                                    <div className="form-check form-check-inline align-middle">
                                      {/* <input className="form-check-input p-2"
                                        type="radio"
                                        name="inlineRadioOp"
                                        id="inlinadio1"
                                        value={innerdata.public_id}
                                        checked={innerdata.public_id == radiochecked}
                                        onChange={(e) => {
                                          bidDataById.map((el) => el.biddings.map((bid) => {
                                            if (el.bid_public_id && bid.public_id === e.target.value) {
                                              setTransportedID(el.bid_public_id)
                                            }
                                            if (bid.public_id === e.target.value) {
                                              setRadiochecked(bid.public_id)
                                            }
                                          }))
                                        }}
                                      /> */}
                                    </div>
                                    <span className='text-white fs-6 rounded p-2' style={{ backgroundColor: innerdata.rank == 1 ? "#249B3E" : innerdata.rank == 2 ? "#FFC450" : innerdata.rank == 3 ? "#FC8019" : innerdata.rank == 4 ? "#A0522D" :  innerdata.rank == 5 ?"#FC2525" : "black"  }}>L{innerdata.rank ? innerdata.rank : "0"}</span>
                                    {/* <span className='bg-light text-black fs-6 rounded p-2 border border-1 ms-2'>&#x20b9;{innerdata.amount ? Number(innerdata.amount).toFixed(0) : "0"}</span> */}
                                  </td>
                                )
                              })}
                            </tr>
                          )
                        })
                      }
                      {!loading && bidDataById?.length === 0 && (
                        <tr>
                          <td colSpan={4}>
                            <Card className="border-0 ">
                              <Card.Body>
                                <h6 className="align-middle text-center fs-3 my-5 py-5">
                                  No Data Found
                                </h6>
                              </Card.Body>
                            </Card>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </Modal.Body>
          {/* <Modal.Footer>
            <div className='container-fluid'>
            <div className='row'>
              <div className='col text-start m-0 p-0'>
                <div>
                <select type="select" className='p-2 rounded' placeholder="Please select reason why you're changing" name="reason" {...register("reason", {required: true})}>
                <option value="" selected disabled  >Please select reason why you're changing </option>
                <option value="Scope adjustment">Scope adjustment</option>
                <option value="Budget revision">Budget revision</option>
                <option value="Resource availability">Resource availability </option>
              </select>
              </div>
              {errors.reason?.type === "required" && <span className='text-danger p-0 m-0'>Please select the reason!</span>}
              </div>
              <div className='col text-end mt-0'>
                {loading8 ? <button onClick={handleSubmit(closedBid)} className='btn px-5 me-2 text-white' style={{ backgroundColor: "#D5A86C" }}>Accepting...<span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                /></button> : <button onClick={handleSubmit(closedBid)} className='btn px-5 me-2 text-white' style={{ backgroundColor: "#D5A86C" }}>Accept</button>}
              </div>
            </div>
            </div> 
          </Modal.Footer> */}
        </Modal>
        {/* --------------------------------All Bid modal--------------------------------- */}
        <Modal show={showPopups} onHide={()=>{setShowPopUps(false)}} size="lg"  scrollable >
          <Modal.Header closeButton style={{ backgroundColor: "#1AA4C8" }}>
            <Modal.Title className='text-white'>
              Bid ID #{bidNumbers ? bidNumbers : "Not Available"}
            </Modal.Title>
          </Modal.Header>
          {cloneBid? <Modal.Body>
            <Row className="d-flex justify-content-center p-0 m-0  mt-3 " style={{ marginBottom: '90px' }}>

              <Col  className="" >
                
                  <Card.Body className='text-left'>
                    <Form onSubmit={handleSubmitBid}>
                      <Row>
                        <Col>
                          <Form.Group className="mb-3">
                            <Form.Label style={{ fontSize: '14px', fontWeight: 'bold' }}>Select Vehicle</Form.Label>

                            {vehicleList.length === 0 ? <Form.Select><option style={{ padding: '15px' }}>Not Found</option></Form.Select> : <Form.Select onChange={(event) => { setPublicId(event.target.value); console.log(event.target.value); }} style={{ padding: '15px' }} >
                            <option value={bidAccordionoutData.required_vehicle_public_id} >{bidAccordionoutData.required_vehicle_name}</option>

                              {vehicleList.map((item) => {

                                return (<option key={item.public_id} value={item.public_id} >{item.name}</option>)
                              })
                              }
                            </Form.Select>}
                            {publicId === '' ? <small className="text-danger">{addData.publicIdErr}</small> : null}
                          </Form.Group>

                        </Col>
                        <Col>
                          <Form.Group className="mb-3">
                            <Form.Label style={{ fontSize: '14px', fontWeight: 'bold' }}>Product Name</Form.Label>
                            <Form.Control type="text" placeholder="Enter Product name" style={{ padding: '15px' }}
                            defaultValue={addData.productName}
                            onChange={(e) => { e.target.value !== '' ? setAddData({ ...addData, productName: e.target.value, productNameerr: '' }) : setAddData({ ...addData, productName: '', productNameerr: 'Product name  is required.' }) ; }} />
                      
                            <small className="text-danger">{addData.productNameerr}</small>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className='mt-4'>
                        <Col>
                          <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label style={{ fontSize: '14px', fontWeight: 'bold' }}>Weight in tons (approx)</Form.Label>
                            <Form.Control type="number" placeholder="Enter Weight in tons (approx)" style={{ padding: '15px' }}
                              defaultValue={addData.weight} onChange={(e) => { e.target.value !== '' ? setAddData({ ...addData, weight: e.target.value, weighterr: '' }) : setAddData({ ...addData, weight: '', weighterr: 'Weight  is required.' }) }} />
                            <small className="text-danger">{addData.weighterr}</small>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label style={{ fontSize: '14px', fontWeight: 'bold' }}>Height in feet (approx)</Form.Label>
                            <Form.Control type="number" placeholder="Enter Height in feet (approx)" style={{ padding: '15px' }}
                              defaultValue={addData.height} onChange={(e) => { e.target.value !== '' ? setAddData({ ...addData, height: e.target.value, heighterr: '' }) : setAddData({ ...addData, height: '', heighterr: 'Height  is required.' }) }} />
                            <small className="text-danger">{addData.heighterr}</small>
                          </Form.Group>
                        </Col>

                        <Col>
                          <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label style={{ fontSize: '14px', fontWeight: 'bold' }}>Length in feet (approx)</Form.Label>
                            <Form.Control type="number" placeholder="Enter Length in feet (approx)" style={{ padding: '15px' }}
                              defaultValue={addData.length} onChange={(e) => { e.target.value !== '' ? setAddData({ ...addData, length: e.target.value, lengtherr: '' }) : setAddData({ ...addData, length: '', lengtherr: 'Length  is required.' }) }} />
                            <small className="text-danger">{addData.lengtherr}</small>
                          </Form.Group>
                        </Col>
                      </Row>

                      <Accordion >
                        <Row className='mt-4'>
                          <Col>
                            <Accordion.Item eventKey="0" >
                              <Accordion.Header style={{ fontSize: '14px', fontWeight: 'bold' }}>Delivery Address 1</Accordion.Header>
                              <Accordion.Body>
                                <Form.Group className="mb-3">

                                  <Form.Control type="text" placeholder="Dealer Name"
                                    defaultValue={addData.dealerName} onChange={(e) => { e.target.value !== '' ? setAddData({ ...addData, dealerName: e.target.value, dealerNameerr: '' }) : setAddData({ ...addData, dealerName: '', dealerNameerr: 'dealer name  is required.' }) }} />
                                  <small className="text-danger">{addData.dealerNameerr}</small>

                                </Form.Group>
                                <Form.Group className="mb-3">
                                  <Form.Control type="text" placeholder="DO Number / Details"
                                    defaultValue={addData.doNumber} onChange={(e) => { e.target.value !== '' ? setAddData({ ...addData, doNumber: e.target.value, doNumbererr: '' }) : setAddData({ ...addData, doNumber: '', doNumbererr: 'Do number is required.' }) }} />
                                  <small className="text-danger">{addData.doNumbererr}</small>

                                </Form.Group>
                                <Form.Group className="mb-3">
                                  <Form.Control type="text" placeholder="Full Address"
                                    defaultValue={addData.locationName} onChange={(e) => { e.target.value !== '' ? setAddData({ ...addData, locationName: e.target.value, locationNameerr: '' }) : setAddData({ ...addData, locationName: '', locationNameerr: 'Address is required.' }) }} />
                                  <small className="text-danger">{addData.locationNameerr}</small>

                                </Form.Group>
                                <Form.Group className="mb-3">
                                  <Form.Control type="text" placeholder="City"
                                    defaultValue={addData.city} onChange={(e) => { e.target.value !== '' ? setAddData({ ...addData, city: e.target.value, cityerr: '' }) : setAddData({ ...addData, city: '', cityerr: 'City is required.' }) }} />
                                  <small className="text-danger">{addData.cityerr}</small>

                                </Form.Group>
                                <Form.Group className="mb-3">
                                  <Form.Control type="number" placeholder="Pincode"
                                    maxLength={6} value={addData.pincode} onChange={(e) => { e.target.value !== '' || e.target.value.length > e.target.maxLength ? setAddData({ ...addData, pincode: e.target.value.slice(0, e.target.maxLength), pincodeerr: '' }) : setAddData({ ...addData, pincode: '', pincodeerr: "Pincode number is required" }) }} />
                                  <small className="text-danger">{addData.pincodeerr}</small>

                                </Form.Group>
                                <Form.Group className="mb-3">
                                  <Form.Control type="text" placeholder="State"
                                    defaultValue={addData.state} onChange={(e) => { e.target.value !== '' ? setAddData({ ...addData, state: e.target.value, stateerr: '' }) : setAddData({ ...addData, state: '', stateerr: 'state is required.' }) }} />
                                  <small className="text-danger">{addData.stateerr}</small>

                                </Form.Group>
                                <Form.Group className="mb-3">
                                  <Form.Control type="number" placeholder="Mobile Number"
                                    maxLength={10} value={addData.mobileNumber} onChange={(e) => { e.target.value !== '' || e.target.value.length > e.target.maxLength ? setAddData({ ...addData, mobileNumber: e.target.value.slice(0, e.target.maxLength), mobileNumbererr: '' }) : setAddData({ ...addData, mobileNumber: '', mobileNumbererr: "Mobile number is required" }) }} />
                                  <small className="text-danger">{addData.mobileNumbererr}</small>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                  <Form.Control type="text" placeholder="Remark"
                                    defaultValue={addData.description} onChange={(e) => { e.target.value !== '' ? setAddData({ ...addData, description: e.target.value, descriptionerr: '' }) : setAddData({ ...addData, description: '', descriptionerr: 'Description is required.' }) }} />
                                  <small className="text-danger">{addData.descriptionerr}</small>

                                </Form.Group>
                              </Accordion.Body>
                            </Accordion.Item>
                            {addData.description === '' ? <small className="text-danger">{addData.addresserr}</small> : null}
                          </Col>
                          <Col>
                            <Accordion.Item eventKey="1" style={{ borderTop: '1px solid #DEE2E6', borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>
                              <Accordion.Header>Delivery Address 2 (optional)</Accordion.Header>
                              <Accordion.Body>
                                <Form.Group className="mb-3">

                                  <Form.Control type="text" placeholder="Dealer Name"
                                    defaultValue={dealerName2} onChange={(e) => { setDealerName2(e.target.value) }} />

                                </Form.Group>
                                <Form.Group className="mb-3">
                                  <Form.Control type="text" placeholder="DO Number / Details"
                                    defaultValue={doNumber2} onChange={(e) => { setDoNumber2(e.target.value) }} />

                                </Form.Group>
                                <Form.Group className="mb-3">
                                  <Form.Control type="text" placeholder="Full Address"
                                    defaultValue={locationName2} onChange={(e) => { setLocationName2(e.target.value) }} />

                                </Form.Group>
                                <Form.Group className="mb-3">
                                  <Form.Control type="text" placeholder="City"
                                    defaultValue={city2} onChange={(e) => { setCity2(e.target.value) }} />

                                </Form.Group>
                                <Form.Group className="mb-3">
                                  <Form.Control type="text" placeholder="Pincode"
                                    defaultValue={pincode2} onChange={(e) => { setPincode2(e.target.value) }} />

                                </Form.Group>
                                <Form.Group className="mb-3">
                                  <Form.Control type="text" placeholder="State"
                                    defaultValue={state2} onChange={(e) => { setState2(e.target.value) }} />

                                </Form.Group>
                                <Form.Group className="mb-3">
                                  <Form.Control type="number" placeholder="Mobile Number"
                                    defaultValue={mobileNumber2} onChange={(e) => { setMobileNumber2(e.target.value) }} />

                                </Form.Group>
                                <Form.Group className="mb-3">
                                  <Form.Control type="text" placeholder="Remark"
                                    defaultValue={description2} onChange={(e) => { setDescription2(e.target.value) }} />

                                </Form.Group>
                              </Accordion.Body>
                            </Accordion.Item>
                          </Col>
                        </Row>
                        <Row className='mt-4'>
                          <Col>
                            <Accordion.Item eventKey="2" className='mt-4' style={{ borderTop: '1px solid #DEE2E6', borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>
                              <Accordion.Header>Delivery Address 3 (optional)</Accordion.Header>
                              <Accordion.Body>
                                <Form.Group className="mb-3">

                                  <Form.Control type="text" placeholder="Dealer Name"
                                    defaultValue={dealerName3} onChange={(e) => { setDealerName3(e.target.value) }} />

                                </Form.Group>
                                <Form.Group className="mb-3">
                                  <Form.Control type="text" placeholder="DO Number / Details"
                                    defaultValue={doNumber3} onChange={(e) => { setDoNumber3(e.target.value) }} />

                                </Form.Group>
                                <Form.Group className="mb-3">
                                  <Form.Control type="text" placeholder="Full Address"
                                    defaultValue={locationName3} onChange={(e) => { setLocationName3(e.target.value) }} />

                                </Form.Group>
                                <Form.Group className="mb-3">
                                  <Form.Control type="text" placeholder="City"
                                    defaultValue={city3} onChange={(e) => { setCity3(e.target.value) }} />

                                </Form.Group>
                                <Form.Group className="mb-3">
                                  <Form.Control type="text" placeholder="Pincode"
                                    defaultValue={pincode3} onChange={(e) => { setPincode3(e.target.value) }} />

                                </Form.Group>
                                <Form.Group className="mb-3">
                                  <Form.Control type="text" placeholder="State"
                                    defaultValue={state3} onChange={(e) => { setState3(e.target.value) }} />

                                </Form.Group>
                                <Form.Group className="mb-3">
                                  <Form.Control type="text" placeholder="Mobile Number"
                                    defaultValue={mobileNumber3} onChange={(e) => { setMobileNumber3(e.target.value) }} />

                                </Form.Group>
                                <Form.Group className="mb-3">
                                  <Form.Control type="text" placeholder="Remark"
                                    defaultValue={description3} onChange={(e) => { setDescription3(e.target.value) }} />

                                </Form.Group>
                              </Accordion.Body>
                            </Accordion.Item>
                          </Col>
                          <Col>
                            <Accordion.Item eventKey="3" className='mt-4' style={{ borderTop: '1px solid #DEE2E6', borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>
                              <Accordion.Header>Delivery Address 4 (optional)</Accordion.Header>
                              <Accordion.Body>
                                <Form.Group className="mb-3">

                                  <Form.Control type="text" placeholder="Dealer Name"
                                    defaultValue={dealerName4} onChange={(e) => { setDealerName4(e.target.value) }} />

                                </Form.Group>
                                <Form.Group className="mb-3">
                                  <Form.Control type="text" placeholder="DO Number / Details"
                                    defaultValue={doNumber4} onChange={(e) => { setDoNumber4(e.target.value) }} />

                                </Form.Group>
                                <Form.Group className="mb-3">
                                  <Form.Control type="text" placeholder="Full Address"
                                    defaultValue={locationName4} onChange={(e) => { setLocationName4(e.target.value) }} />

                                </Form.Group>
                                <Form.Group className="mb-3">
                                  <Form.Control type="text" placeholder="City"
                                    defaultValue={city4} onChange={(e) => { setCity4(e.target.value) }} />

                                </Form.Group>
                                <Form.Group className="mb-3">
                                  <Form.Control type="text" placeholder="Pincode"
                                    defaultValue={pincode4} onChange={(e) => { setPincode4(e.target.value) }} />

                                </Form.Group>
                                <Form.Group className="mb-3">
                                  <Form.Control type="text" placeholder="State"
                                    defaultValue={state4} onChange={(e) => { setState4(e.target.value) }} />

                                </Form.Group>
                                <Form.Group className="mb-3">
                                  <Form.Control type="text" placeholder="Mobile Number"
                                    defaultValue={mobileNumber4} onChange={(e) => { setMobileNumber4(e.target.value) }} />

                                </Form.Group>
                                <Form.Group className="mb-3">
                                  <Form.Control type="text" placeholder="Remark"
                                    defaultValue={description4} onChange={(e) => { setDescription4(e.target.value) }} />

                                </Form.Group>
                              </Accordion.Body>
                            </Accordion.Item>

                          </Col>

                        </Row>
                        <Row className='mt-4'>
                          <Col>
                            <Accordion.Item eventKey="4" className='mt-4' style={{ borderTop: '1px solid #DEE2E6', borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>
                              <Accordion.Header>Delivery Address 5 (optional)</Accordion.Header>
                              <Accordion.Body>
                                <Form.Group className="mb-3">

                                  <Form.Control type="text" placeholder="Dealer Name"
                                    defaultValue={dealerName5} onChange={(e) => { setDealerName5(e.target.value) }} />

                                </Form.Group>
                                <Form.Group className="mb-3">
                                  <Form.Control type="text" placeholder="DO Number / Details"
                                    defaultValue={doNumber5} onChange={(e) => { setDoNumber5(e.target.value) }} />

                                </Form.Group>
                                <Form.Group className="mb-3">
                                  <Form.Control type="text" placeholder="Full Address"
                                    defaultValue={locationName5} onChange={(e) => { setLocationName5(e.target.value) }} />

                                </Form.Group>
                                <Form.Group className="mb-3">
                                  <Form.Control type="text" placeholder="City"
                                    defaultValue={city5} onChange={(e) => { setCity5(e.target.value) }} />

                                </Form.Group>
                                <Form.Group className="mb-3">
                                  <Form.Control type="text" placeholder="Pincode"
                                    defaultValue={pincode5} onChange={(e) => { setPincode5(e.target.value) }} />

                                </Form.Group>
                                <Form.Group className="mb-3">
                                  <Form.Control type="text" placeholder="State"
                                    defaultValue={state5} onChange={(e) => { setState5(e.target.value) }} />

                                </Form.Group>
                                <Form.Group className="mb-3">
                                  <Form.Control type="text" placeholder="Mobile Number"
                                    defaultValue={mobileNumber5} onChange={(e) => { setMobileNumber5(e.target.value) }} />

                                </Form.Group>
                                <Form.Group className="mb-3">
                                  <Form.Control type="text" placeholder="Remark"
                                    defaultValue={description5} onChange={(e) => { setDescription5(e.target.value) }} />

                                </Form.Group>
                              </Accordion.Body>
                            </Accordion.Item>
                          </Col>
                          <Col>
                            <Accordion.Item eventKey="5" className='mt-4' style={{ borderTop: '1px solid #DEE2E6', borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>
                              <Accordion.Header>Delivery Address 6 (optional)</Accordion.Header>
                              <Accordion.Body>
                                <Form.Group className="mb-3">

                                  <Form.Control type="text" placeholder="Dealer Name"
                                    defaultValue={dealerName6} onChange={(e) => { setDealerName6(e.target.value) }} />

                                </Form.Group>
                                <Form.Group className="mb-3">
                                  <Form.Control type="text" placeholder="DO Number / Details"
                                    defaultValue={doNumber6} onChange={(e) => { setDoNumber6(e.target.value) }} />

                                </Form.Group>
                                <Form.Group className="mb-3">
                                  <Form.Control type="text" placeholder="Full Address"
                                    defaultValue={locationName6} onChange={(e) => { setLocationName6(e.target.value) }} />

                                </Form.Group>
                                <Form.Group className="mb-3">
                                  <Form.Control type="text" placeholder="City"
                                    defaultValue={city6} onChange={(e) => { setCity6(e.target.value) }} />

                                </Form.Group>
                                <Form.Group className="mb-3">
                                  <Form.Control type="text" placeholder="Pincode"
                                    defaultValue={pincode6} onChange={(e) => { setPincode6(e.target.value) }} />

                                </Form.Group>
                                <Form.Group className="mb-3">
                                  <Form.Control type="text" placeholder="State"
                                    defaultValue={state6} onChange={(e) => { setState6(e.target.value) }} />

                                </Form.Group>
                                <Form.Group className="mb-3">
                                  <Form.Control type="text" placeholder="Mobile Number"
                                    defaultValue={mobileNumber6} onChange={(e) => { setMobileNumber6(e.target.value) }} />

                                </Form.Group>
                                <Form.Group className="mb-3">
                                  <Form.Control type="text" placeholder="Remark"
                                    defaultValue={description6} onChange={(e) => { setDescription6(e.target.value) }} />

                                </Form.Group>
                              </Accordion.Body>
                            </Accordion.Item>

                          </Col>

                        </Row>
                      </Accordion>
                      <Row className='mt-5'>
                        <Col>
                          <Form.Group className="mb-3">
                            <Form.Label style={{ fontSize: '14px', fontWeight: 'bold' }}>Bid Start time</Form.Label>
                            <DatePicker
                              inputProps={{
                                style: { padding: '15px' },
                                disabled: true,

                              }}
                              value={dateValue}
                              dateFormat="DD-MM-YYYY"
                              timeFormat="hh:mm A"

                            />
                          </Form.Group>

                        </Col>

                        <Col>
                          <Form.Group className="mb-3">
                            <Form.Label style={{ fontSize: '14px', fontWeight: 'bold' }}>Select Bid End time</Form.Label>
                            <DatePicker
                              inputProps={{
                                style: { padding: '15px' }
                              }}

                            value={endTime}
                            dateFormat={false}
                            timeFormat="hh:mm A"
                            isValidDate={isValidDate} // Disable past dates
                            isValidTime={isValidTime} // Disable past time and time before current time
                            // timeConstraints={timeConstraints} // Add constraints to the timepicker
                            onChange={(date) => setEndTime(date)}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className='mt-3'>
                        <Col>
                          <Form.Group className="mb-3">
                            <Form.Label style={{ fontSize: '14px', fontWeight: 'bold' }}>Select truck arrival cut off time</Form.Label>
                            <DatePicker
                              inputProps={{
                                style: { padding: '15px' }
                              }}
                              value={moment(truckArrival).toDate()}
                              dateFormat="DD-MM-YYYY"
                              timeFormat="hh:mm A"
                              isValidDate={isValidDate} // Disable past dates
                              isValidTime={isValidTime} // Limit time to 1 to 8 hours from the current time
                              onChange={handleDateChange2}

                            />
                          </Form.Group>
                        </Col>

                        <Col>
                          <Form.Group >
                            <Form.Label style={{ fontSize: '14px', fontWeight: 'bold' }}>Select Publish Bid to</Form.Label>
                            <div >

                              <Multiselect
                                options={groupList.map(group => ({ label: group.name, value: group.id }))}
                              selectedValues={groupName.map(group => ({ label: group.name, value: group.id }))}
                                displayValue="label"
                                style={{ inputField: { padding: '10px' } }}
                                onSelect={handleGroupSelect} // Use the custom onSelect handler
                                onRemove={handleGroupRemove} // Use the custom onRemove handler


                              />
                              {groupError && (
                                <p style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>
                                  {groupError}
                                </p>
                              )}
                            </div>
                          </Form.Group>

                        </Col>
                      </Row>

                      <div className='d-flex justify-content-center mt-2'>
                        {loadingBid ? <Button className='BTN_Color' style={{ border: '#00d5ae', paddingLeft: '30px', paddingRight: '30px', }} type="submit" disabled>
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true" /> Sending....
                        </Button> : <Button className='BTN_Color' style={{ border: '#00d5ae', paddingLeft: '30px', paddingRight: '30px', }} type="submit">
                          Send
                        </Button>}
                      </div>
                    </Form>
                  </Card.Body>
                
              </Col>
            </Row>
          </Modal.Body>:
           <Modal.Body>
            <div className='row my-3 '>
              <div className='col-5'>
                {/* <p><span className='fw-semibold'>Bid ID :- </span>{bidAccordionoutData.bid_number ? bidAccordionoutData.bid_number : "Not Available"}</p> */}
                <p><span className='fw-semibold'>Product :- </span>{bidAccordionoutData.product_name ? bidAccordionoutData.product_name : "Not Available"}</p>
                <p><span className='fw-semibold'>Truck Arrival Cut Off :- </span>{moment(bidAccordionoutData.truck_arrival_time).format("DD-MMMM-YYYY, LT") ? moment(bidAccordionoutData.truck_arrival_time).format("DD-MMMM-YYYY, LT") : "Not Available"}</p>
                <p><span className='fw-semibold'>Weight :- </span>{bidAccordionoutData.weight ? bidAccordionoutData.weight : "Not Available"} {bidAccordionoutData.weight_unit}</p>
                <p><span className='fw-semibold'>Vehicle Type :- </span>{bidAccordionoutData.required_vehicle_name ? bidAccordionoutData.required_vehicle_name : "Not Available"}</p>
                {/* <p><span className='fw-semibold'>Vehicle Max Load :- </span>{bidAccordionoutData.required_vehicle_max_load ? bidAccordionoutData.required_vehicle_max_load : "Not Available"}</p> */}
                <p><span className='fw-semibold'>Bid Closes In :- </span>{bidAccordionoutData.remain_time ? (bidAccordionoutData.remain_time).split("-") : "Not Available"}</p>
              </div>
              <div className='col-5'>
                <p><span className='fw-semibold'>Height :- </span>{bidAccordionoutData.height ? bidAccordionoutData.height : "Not Available"} {bidAccordionoutData.height_unit}</p>
                <p><span className='fw-semibold'>Length :- </span>{bidAccordionoutData.length ? bidAccordionoutData.length : "Not Available"} {bidAccordionoutData.length_unit}</p>
                <p><span className='fw-semibold'>Start Time :- </span>{moment(bidAccordionoutData.start_time).format("DD-MMMM-YYYY, LT") ? moment(bidAccordionoutData.start_time).format("DD-MMMM-YYYY, LT") : "Not Available"}</p>
                <p><span className='fw-semibold'>End Time :- </span>{moment(bidAccordionoutData.end_time).format("DD-MMMM-YYYY, LT") ? moment(bidAccordionoutData.end_time).format("DD-MMMM-YYYY, LT") : "Not Available"}</p>
                {/* <p><span className='fw-semibold'>Expected Deliver Time :- </span>{moment(bidAccordionoutData.expected_deliver_time).format("DD-MMMM-YYYY, LT") ? moment(bidAccordionoutData.expected_deliver_time).format("DD-MMMM-YYYY, LT") : "Not Available"}</p> */}
                {/* <p><span className='fw-semibold'>Amount :- </span>&#x20b9; {bidAccordionoutData.base_price ? bidAccordionoutData.base_price : "Not Available"}</p> */}
              </div>
              <div className='col-2'>
                <button className='btn btn-lg' style={{ backgroundColor: "#8D6BA6",color:'white' }} onClick={()=>setCloneBid(true)}>Clone</button>
              </div>
            </div>
            <div className='row mt-3 mb-3'>
              <div className='col'>
                <div className='table-responsive'>
                  <span className='small fw-semibold ms-2 mb-1'>Delivery Points</span>
                  <Accordion>
                    {loading3 && <Spinner />}
                    {bidAccordionData.length > 0 && bidAccordionData.map((item, index) => {
                    return (
                      <Accordion.Item className='mb-3' eventKey={item.public_id}>
                        <Accordion.Header  >Unloading Points {index + 1}</Accordion.Header>
                        <Accordion.Body>
                          <p><span className='fw-semibold'>Dealer Name :- </span>{item.dealer_name ? item.dealer_name : "Not Available"}</p>
                          {/* <p><span className='fw-semibold'>Landmark :- </span>{item.landmark ? item.landmark : "Not Available"}</p> */}
                          <p><span className='fw-semibold'>Location :- </span>{item.name_of_location ? item.name_of_location : "Not Available"}</p>
                          <p><span className='fw-semibold'>City :- </span>{item.city ? item.city : "Not Available"}</p>
                          <p><span className='fw-semibold'>State :- </span>{item.state ? item.state : "Not Available"}</p>
                          <p><span className='fw-semibold'>Pincode :- </span>{item.pincode ? item.pincode : "Not Available"}</p>
                          <p><span className='fw-semibold'>Mobile No. :- </span>{item.alternate_mobile ? item.alternate_mobile : "Not Available"}</p>
                          <p><span className='fw-semibold'>Description :- </span>{item.description ? item.description : "Not Available"}</p>
                        </Accordion.Body>
                      </Accordion.Item>
                    )
                    })}
                  </Accordion>
                </div>
              </div>
            </div>
          </Modal.Body>}
        </Modal>
        {/* --------------------------------Dispatched modal--------------------------------- */}
        <Modal show={showPopups3} onHide={()=>{ setShowPopUps3(false)}} size="lg" className="mt-5" scrollable>
          <Modal.Header closeButton style={{ backgroundColor: "#1aa4c8" }}>
            <Modal.Title className='text-white'>
              Bid ID #{bidNumbers3 ? bidNumbers3 : "Not Available"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='row my-3 '>
              <div className='col-6'>
                {/* <p><span className='fw-semibold'>Bid ID :- </span>{bidDispatchedoutData.bid_number ? bidDispatchedoutData.bid_number : "Not Available"}</p> */}
                <p><span className='fw-semibold'>Product :- </span>{bidDispatchedoutData.product_name ? bidDispatchedoutData.product_name : "Not Available"}</p>
                <p><span className='fw-semibold'>Truck Arrival Cut Off :- </span>{moment(bidDispatchedoutData.truck_arrival_time).format("DD-MMMM-YYYY, LT") ? moment(bidDispatchedoutData.truck_arrival_time).format("DD-MMMM-YYYY, LT") : "Not Available"}</p>
                <p><span className='fw-semibold'>Weight :- </span>{bidDispatchedoutData.weight ? bidDispatchedoutData.weight : "Not Available"} {bidDispatchedoutData.weight_unit}</p>
                <p><span className='fw-semibold'>Vehicle Type :- </span>{bidDispatchedoutData.required_vehicle_name ? bidDispatchedoutData.required_vehicle_name : "Not Available"}</p>
                {/* <p><span className='fw-semibold'>Vehicle Max Load :- </span>{bidDispatchedoutData.required_vehicle_max_load ? bidDispatchedoutData.required_vehicle_max_load : "Not Available"}</p> */}
                <p><span className='fw-semibold'>Bid Closes In :- </span>{bidDispatchedoutData.remain_time ? (bidDispatchedoutData.remain_time).split("-") : "Not Available"}</p>
              </div>
              <div className='col-6'>
                <p><span className='fw-semibold'>Height :- </span>{bidDispatchedoutData.height ? bidDispatchedoutData.height : "Not Available"} {bidDispatchedoutData.height_unit}</p>
                <p><span className='fw-semibold'>Length :- </span>{bidDispatchedoutData.length ? bidDispatchedoutData.length : "Not Available"} {bidDispatchedoutData.length_unit}</p>
                <p><span className='fw-semibold'>Start Time :- </span>{moment(bidDispatchedoutData.start_time).format("DD-MMMM-YYYY, LT") ? moment(bidDispatchedoutData.start_time).format("DD-MMMM-YYYY, LT") : "Not Available"}</p>
                <p><span className='fw-semibold'>End Time :- </span>{moment(bidDispatchedoutData.end_time).format("DD-MMMM-YYYY, LT") ? moment(bidDispatchedoutData.end_time).format("DD-MMMM-YYYY, LT") : "Not Available"}</p>
                {/* <p><span className='fw-semibold'>Expected Deliver Time :- </span>{moment(bidDispatchedoutData.expected_deliver_time).format("DD-MMMM-YYYY, LT") ? moment(bidDispatchedoutData.expected_deliver_time).format("DD-MMMM-YYYY, LT") : "Not Available"}</p> */}
                {/* <p><span className='fw-semibold'>Amount :- </span>&#x20b9; {bidDispatchedoutData.base_price ? bidDispatchedoutData.base_price : "Not Available"}</p> */}
              </div>
            </div>
            <div className='row mt-3 mb-3'>
              <div className='col'>
                <div className='table-responsive'>
                  <span className='small fw-semibold ms-2 mb-1'>Delivery Points</span>
                  <Accordion>
                    {loading6 && <Spinner />}
                    {bidDispatchedData.length > 0 && bidDispatchedData.map((item, index) => {
                      return(
                      <Accordion.Item eventKey={item.public_id} className='mb-3'>
                        <Accordion.Header >Unloading Points {index + 1}</Accordion.Header>
                        <Accordion.Body>
                          <p><span className='fw-semibold'>Dealer Name :- </span>{item.dealer_name ? item.dealer_name : "Not Available"}</p>
                          {/* <p><span className='fw-semibold'>Landmark :- </span>{item.landmark ? item.landmark : "Not Available"}</p> */}
                          <p><span className='fw-semibold'>Location :- </span>{item.name_of_location ? item.name_of_location : "Not Available"}</p>
                          <p><span className='fw-semibold'>City :- </span>{item.city ? item.city : "Not Available"}</p>
                          <p><span className='fw-semibold'>Pincode :- </span>{item.pincode ? item.pincode : "Not Available"}</p>
                          <p><span className='fw-semibold'>Mobile No. :- </span>{item.alternate_mobile ? item.alternate_mobile : "Not Available"}</p>
                          <p><span className='fw-semibold'>Description :- </span>{item.description ? item.description : "Not Available"}</p>
                        </Accordion.Body>
                      </Accordion.Item>
                    )})}
                  </Accordion>
                </div>
              </div>
            </div>
            {bidvehicleData.length > 0 && bidvehicleData.map((item, index) => {
              return(
              <div className='row my-3' key={index}>
                <div className='col-6'>
                  <p><span className='fw-semibold'>Driver Name :- </span>{item.driver_name ? item.driver_name : "Not Available"}</p>
                  <p><span className='fw-semibold'>Contact No. :- </span>{item.driver_contact ? item.driver_contact : "Not Available"}</p>
                  <p><span className='fw-semibold'>Arrival Time :- </span>{moment(item.estimated_arrival_time).format("DD-MMMM-YYYY, LT") ? moment(item.estimated_arrival_time).format("DD-MMMM-YYYY, LT") : "Not Available"}</p>
                </div>
                <div className='col-6'>
                  <p><span className='fw-semibold'>Registration No. :- </span>{item.truck_registration_number ? item.truck_registration_number : "Not Available"}</p>
                  <p><span className='fw-semibold'>Alternate Contact No. :- </span>{item.alternate_contact ? item.alternate_contact : "Not Available"}</p>
                </div>
              </div>
            )})}
          </Modal.Body>
        </Modal>
        {/* --------------------------------ongoing modal---------------------------------- */}
        <Modal show={showPopups2} onHide={()=>{setShowPopUps2(false)}} size="lg" className="mt-5" scrollable>
          <Modal.Header closeButton onClick={() => { setShowC(false) }} style={{ backgroundColor: "#1aa4c8" }}>
            <Modal.Title className='text-white'>
              Bid ID #{bidNumbers4 ? bidNumbers4 : "Not Available"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='row my-3 '>
              <div className='col-6'>
                {/* <p><span className='fw-semibold'>Bid ID :- </span>{bidAccordionongoingoutData.bid_number ? bidAccordionongoingoutData.bid_number : "Not Available"}</p> */}
                <p><span className='fw-semibold'>Product :- </span>{bidAccordionongoingoutData.product_name ? bidAccordionongoingoutData.product_name : "Not Available"}</p>
                <p><span className='fw-semibold'>Truck Arrival Cut Off :- </span>{moment(bidAccordionongoingoutData.truck_arrival_time).format("DD-MMMM-YYYY, LT") ? moment(bidAccordionongoingoutData.truck_arrival_time).format("DD-MMMM-YYYY, LT") : "Not Available"}</p>
                <p><span className='fw-semibold'>Weight :- </span>{bidAccordionongoingoutData.weight ? bidAccordionongoingoutData.weight : "Not Available"} {bidAccordionongoingoutData.weight_unit}</p>
                <p><span className='fw-semibold'>Vehicle Type :- </span>{bidAccordionongoingoutData.required_vehicle_name ? bidAccordionongoingoutData.required_vehicle_name : "Not Available"}</p>
                {/* <p><span className='fw-semibold'>Vehicle Max Load :- </span>{bidAccordionongoingoutData.required_vehicle_max_load ? bidAccordionongoingoutData.required_vehicle_max_load : "Not Available"}</p> */}
                <p><span className='fw-semibold'>Bid Closes In :- </span>{bidAccordionongoingoutData.remain_time ? (bidAccordionongoingoutData.remain_time).split("-") : "Not Available"}</p>
              </div>
              <div className='col-6'>
                <p><span className='fw-semibold'>Height :- </span>{bidAccordionongoingoutData.height ? bidAccordionongoingoutData.height : "Not Available"} {bidAccordionongoingoutData.height_unit}</p>
                <p><span className='fw-semibold'>Length :- </span>{bidAccordionongoingoutData.length ? bidAccordionongoingoutData.length : "Not Available"} {bidAccordionongoingoutData.length_unit}</p>
                <p><span className='fw-semibold'>Start Time :- </span>{moment(bidAccordionongoingoutData.start_time).format("DD-MMMM-YYYY, LT") ? moment(bidAccordionongoingoutData.start_time).format("DD-MMMM-YYYY, LT") : "Not Available"}</p>
                <p><span className='fw-semibold'>End Time :- </span>{moment(bidAccordionongoingoutData.end_time).format("DD-MMMM-YYYY, LT") ? moment(bidAccordionongoingoutData.end_time).format("DD-MMMM-YYYY, LT") : "Not Available"}</p>
                {/* <p><span className='fw-semibold'>Expected Deliver Time :- </span>{moment(bidAccordionongoingoutData.expected_deliver_time).format("DD-MMMM-YYYY, LT") ? moment(bidAccordionongoingoutData.expected_deliver_time).format("DD-MMMM-YYYY, LT") : "Not Available"}</p> */}
                {/* <p><span className='fw-semibold'>Amount :- </span>&#x20b9; {bidAccordionongoingoutData.base_price ? bidAccordionongoingoutData.base_price : "Not Available"}</p> */}
              </div>
            </div>
            <div className='row mt-3 mb-3'>
              <div className='col'>
                <div className='table-responsive'>
                  <span className='small fw-semibold ms-2 mb-1'>Delivery Points</span>
                  <Accordion>
                    {loading7 && <Spinner />}
                    {bidAccordionongoingData.length > 0 && bidAccordionongoingData.map((item, index) => {
                      return(
                      <Accordion.Item eventKey={item.public_id} className='mb-3'>
                        <Accordion.Header >Unloading Points {index + 1}</Accordion.Header>
                        <Accordion.Body>
                          <p><span className='fw-semibold'>Dealer Name :- </span>{item.dealer_name ? item.dealer_name : "Not Available"}</p>
                          {/* <p><span className='fw-semibold'>Landmark :- </span>{item.landmark ? item.landmark : "Not Available"}</p> */}
                          <p><span className='fw-semibold'>Location :- </span>{item.name_of_location ? item.name_of_location : "Not Available"}</p>
                          <p><span className='fw-semibold'>City :- </span>{item.city ? item.city : "Not Available"}</p>
                          <p><span className='fw-semibold'>Pincode :- </span>{item.pincode ? item.pincode : "Not Available"}</p>
                          <p><span className='fw-semibold'>Mobile No. :- </span>{item.alternate_mobile ? item.alternate_mobile : "Not Available"}</p>
                          <p><span className='fw-semibold'>Description :- </span>{item.description ? item.description : "Not Available"}</p>
                        </Accordion.Body>
                      </Accordion.Item>
                    )})}
                  </Accordion>
                </div>
              </div>
            </div>
            <div className='row mt-3 mb-3'>
              <div className='col text-end'>
                <button className='btn px-3 text-white BTN_Color' onClick={() => {
                  if (showC === false) {
                    setShowC(true)
                  } else {
                    setShowC(false)
                  }
                  getBidOngoingTname(bidAccordionongoingoutData.public_id)
                }}>Details</button>
              </div>
            </div>
            {showC === true && (<>
              <div className='row my-3'>
                <div className='col'>
                  <div className='card border-0 shadow'>
                    <div className="card-header text-white fw-bold fs-5" style={{ backgroundColor: "#1aa4c8" }}>
                      Transporter Names
                    </div>
                    <div className="card-body">
                      {tname.length > 0 && tname.map((item, index) => {
                        return(<>
                        <h6 className="card-title">{index + 1}. {item.transporter_name ? item.transporter_name : "Not Available"}</h6><hr />
                      </>)})}
                    </div>
                  </div>
                </div>
              </div>
            </>)}
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

export default Dashboard
