import React from "react";
import { Col, Row, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function LandingPage(){
    let navigate = useNavigate()
return(
    <>
        <div className='container-fluid mt-4 d-flex align-items-center col-12'>
            <Row className=" col-12 p-0 ml-0 ms-0 d-flex align-items-center justify-content-center my-auto" style={{minHeight:'76vh'}}>
            <Col>
                <Card
                        onClick={() => { navigate('/home') }}
                    className='m-2 rounded border-0 shadow btn p-5' style={{ background: "linear-gradient(180deg, #FFF6EF 0%, #FFFFFF 100%)", cursor: 'pointer' }}>
                    <Card.Body className='d-flex justify-content-center'>
                        <span className='text-start'>

                                <Card.Text style={{ color: "#00d5ae",fontWeight:'bold',fontSize:'28px'  }} >Bids</Card.Text>
                        </span>
                        <span className='text-end'>
                            {/* <img
                           
                                className="img-fluid mx-1 mt-1 mb-0"
                                style={{ width: 36, height: 36 }}
                                alt=""
                            /> */}
                        </span>
                    </Card.Body>
                </Card>
            </Col>
            <Col>
                <Card
                        onClick={() => { navigate('/transporter') }}
                        className='m-2 rounded border-0 shadow btn p-5' style={{ background: "linear-gradient(180deg, #F2FFFF 0%, #FFFFFF 100%)", cursor: 'pointer' }}>
                    <Card.Body className='d-flex justify-content-center'>
                        <span className='text-start'>
             
                                <Card.Text style={{ color: "#00d5ae", fontWeight: 'bold', fontSize: '28px' }}>Transporters</Card.Text>
                        </span>
                        <span className='text-end'>
                            {/* <img
                           
                                className="img-fluid mx-1 mt-1 mb-0"
                                style={{ width: 36, height: 36 }}
                                alt=""
                            /> */}
                        </span>
                    </Card.Body>
                </Card>
            </Col>
  </Row>
  </div>

    </>
)
}
export default LandingPage;