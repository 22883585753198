import axios from 'axios';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify"
import image from "../assets/img/logo.png"
function MyProfile() {
    const [image, setImage] = useState("");
    const [socialImage, setSocialImage] = useState("")
    const [sendImage, setSendImage] = useState(false);
    const [loading, setLoading] = useState(false)
    const [addData, setAddData] = useState({
        name: '', nameerr: '',
        email: '', emailerr: '',
        number: '',

    })
    const [isSocial, setIsSocial] = useState(false)
    const [reload, setReload] = useState(false)
    let navigate = useNavigate()


    // useEffect(() => {
    //     getProfile()
    // }, [reload])


    // const getProfile = () => {

    //     let url_profile = process.env.REACT_APP_BASEURL + '/'
    //     const config_profile = {
    //         headers: {
    //             "Access-Control-Allow-Origin": "*",
    //             "Content-type": "application/json",
    //             "Authorization": `bearer ` + localStorage.getItem('adroit_token'),
    //         }
    //     };
    //     axios.get(url_profile, config_profile)
    //         .then((res) => {
    //             setAddData({
    //                 name: res.data.fullname,
    //                 email: res.data.email,
    //                 number: res.data.phone_no
    //             })
    //             setImage(res.data.profile)
    //             setSocialImage(res.data.image_url)
    //             if (res.data.social_login) {
    //                 setIsSocial(true)
    //             }
    //         }).catch(err => {
    //             if (err.response.status === 401) {
    //                 navigate('/')
    //             }
    //             else {
    //                 toast.error('Something went wrong', {
    //                     position: "top-right",
    //                     autoClose: 3000,
    //                     theme: "colored",
    //                 });
    //             }

    //         })
    // }

    // const handleSubmit = (e) => {
    //     e.preventDefault()
    //     let error = { status: 0, name: '', email: '', }
    //     if (addData.name === '') { error.name = 'Please enter name'; error.status = 1 }
    //     else { error.name = '' }

    //     if (addData.email <= 0 || addData.email === '') { error.email = 'Please enter email'; error.status = 1 }
    //     else { error.email = '' }
    //     setAddData({ ...addData, nameerr: error.name, emailerr: error.email, })
    //     if (error.status === 0) {
    //         setLoading(true)
    //         const formData = new FormData();

    //         if (!(sendImage === false)) {
    //             formData.append("profile", sendImage);

    //         }
    //         formData.append("email", addData.email);
    //         formData.append("fullname", addData.name);
    //         formData.append("phone_no", addData.number);

    //         let url = process.env.REACT_APP_BASEURL + "profile/"
    //         const config = {
    //             headers: {
    //                 "Access-Control-Allow-Origin": "*",
    //                 "Content-type": "multipart/form-data",
    //                 "Authorization": `bearer ` + localStorage.getItem('adroit_token'),
    //             }
    //         };
    //         axios.patch(url, formData, config)
    //             .then(res => {
    //                 setLoading(false)
    //                 setReload(true)
    //                 if (res.data.status_code === 400) {
    //                     toast.error(res.data.message, {
    //                         position: toast.POSITION.TOP_RIGHT,
    //                         autoClose: 3000,
    //                         theme: "colored",
    //                     })
    //                 }
    //                 else if (res.data.status_code === 200) {
    //                     toast.success("Profile updated successfully!", {
    //                         position: toast.POSITION.TOP_RIGHT,
    //                         autoClose: 2000,
    //                         theme: "colored",
    //                     })
    //                     navigate('/')
    //                 }
    //             })
    //             .catch(err => {
    //                 setLoading(false)
    //                 toast.error(`${err.response.data.error}`, {
    //                     autoClose: 1000,
    //                     theme: "colored",
    //                 });
    //             })
    //     }
    // }

    // let imageHandler = (e) => {
    //     const reader = new FileReader();
    //     reader.onload = () => {
    //         if (reader.readyState === 2) {
    //             setImage(reader.result);

    //         }
    //         let a = e.target.files[0]
    //         setSendImage(a)
    //     }
    //     reader.readAsDataURL(e.target.files[0])
    // };
    return (<>
        <Container className='mx-auto d-flex justify-content-center flex-column mb-2' style={{ height: "80vh", }} >
            <Row className='h-100 align-items-center d-flex justify-content-center ' >
                <Col lg={6}>
                    <Card className='ps-5 pe-5 py-3 px-3' >
                        <h2 className='text-center mt-2'>Profile Details</h2>
                        <Card.Body>
                            {/* {JSON.stringify(sendImage)} */}
                            <form encType='multipart/form-data' 
                            // onSubmit={(e) => { handleSubmit(e) }}
                            >
                                <div className="container  ">
                                    <div className='d-flex justify-content-center'>
                                        <div className="img-holder">
                                            {/* {image !== null ?
                                                <img
                                                    alt="Short logo"
                                                    className="img" style={{ borderRadius: "50%" }}
                                                    src={image}
                                                />
                                                :
                                                socialImage !== null ?
                                                    <img
                                                        alt="Short logo"
                                                        className="img" style={{ borderRadius: "50%" }}
                                                        src={socialImage}
                                                    /> :
                                                    <img
                                                        alt="Short logo"
                                                        className="img" style={{ borderRadius: "50%" }}
                                                        src={require('../../assets/images/placeholder.png')}
                                                    />} */}
                                                    <img
                                                    alt="logo"
                                                    className="img" style={{ borderRadius: "50%" }}
                                                    src={image}
                                                />
                                        </div>

                                    </div>
                                    <input type="file" accept="image/*" name="image-upload" id="input" 
                                    // onChange={imageHandler}
                                     />
                                    <div className="label">
                                        <label className="image-upload text-green-500 font-bold" htmlFor="input">
                                            <i className="material-icons "></i>Choose your Photo</label>
                                    </div>
                                </div>
                                <label className="form-label">Full name</label>
                                <input className="form-control" 
                                // value={addData.name} 
                                type='text' placeholder='Enter your full name ' 
                                // onChange={(e) => { e.target.value !== '' ? setAddData({ ...addData, name: e.target.value, nameerr: '' }) : setAddData({ ...addData, name: '', nameerr: 'Name  is required.' }) }}
                                 />
                                {/* {addData.nameerr && <small className="text-danger">{addData.nameerr}<br /></small>} */}

                                <label className="form-label mt-2">Email</label>
                                <input className="form-control"
                                //  value={addData.email} 
                                 type='email' placeholder='Enter your email address' 
                                //  onChange={(e) => { e.target.value !== '' ? setAddData({ ...addData, email: e.target.value, emailerr: '' }) : setAddData({ ...addData, email: '', emailerr: 'Email  is required.' }) }} 
                                 />
                                {/* {addData.emailerr && <small small className="text-danger">{addData.emailerr}<br /></small>} */}

                                <label className="form-label mt-2" >Mobile number</label>
                                <input className="form-control" maxLength={10} 
                                // onInput={(e) => { if (e.target.value.length > e.target.maxLength) { e.target.value = e.target.value.slice(0, e.target.maxLength) } }} value={addData.number}
                                 type='number' placeholder='Enter 10 digit mobile number' 
                                //  onChange={(e) => { setAddData({ ...addData, number: e.target.value }) }}
                                  />

                                <Row className='d-flex justify-content-center mt-4'>
                                    {/* {loading ? <button type='submit' className='btn text-white col-8' style={{ backgroundColor: "#8D6BA6" }} disabled> <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Updating...</button> :
                                        <button disabled={isSocial ? true : false} type='submit' className='btn text-white col-8' style={{ backgroundColor: "#8D6BA6" }}>Update</button>}

                                    {isSocial ?
                                        <small className='text-center text-danger'>Profile update unavailable in social login </small>
                                        : null
                                    } */}
                                    <button type='submit' className='btn text-white col-8 BTN_Color'>Update</button>
                                </Row>
                            </form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    </>)
}

export default MyProfile;
