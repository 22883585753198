import { useState } from 'react';
import { Col } from "react-bootstrap";
import logo from '../assets/img/Bid_logo.png';
import { Link, useNavigate } from "react-router-dom";
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
import { handleError } from "../Utilities/HandleError";
import { AiFillEye, AiOutlineEyeInvisible } from "react-icons/ai";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const togglePasswordVisibility = () => {
    setPasswordVisible((prev) => !prev); // Step 3: Toggle the visibility state
  };

  let navigate = useNavigate()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();


  const onSubmit = (data) => {
    setLoading(true);
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "application/json",
      },
    };
    let body = {
      email: data.email,
      password: data.password,
      is_staff: "false",
    };
    let url = process.env.REACT_APP_BASEURL + "superAdmin/login/";
    axios.post(url, body, config)
      .then((res) => {
        if (res.data.status == 401) {
          toast.error("You have entered wrong credential for login super admin.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 4000,
            theme: "colored",
          });
        } else if (res.data.status == 201) {
          navigate("/dashboard");
          toast.success("Admin Login Successfull!", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
            theme: "colored",
          });
          localStorage.setItem(
            "adroit_token",
            res.data.data.access_token
          );
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.dismiss();
        console.log("errrrr", error)
        if (error.response.data.message) {
          toast.error("You have entered wrong credential for login  admin.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 4000,
            theme: "colored",
          });
        }
        if (error != {}) {
          const getErrRes = handleError(error);
          console.log(error);
          if (getErrRes === 401) {
            navigate("/");
          } else {
            toast.error(getErrRes ? getErrRes : "Something went wrong.", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 4000,
              theme: "colored",
            });
          }
        }
      });
  };

  return (
    <>
      <div style={{ marginBottom: '120px', marginTop: "140px" }}>
        <div className="d-flex justify-content-center ">
          <Col lg={6} >
            <div className="card card-body p-5 " style={{ boxShadow: '-1px 1px 15px 4px #9289897a' }}>
              <form className='p-3 text-center' onSubmit={handleSubmit(onSubmit)}>
                <img src={logo} style={{ marginTop: '-40px', height: '100px' }} alt="title" ></img>
                <h3 className='mt-4 mb-0'>Admin Login</h3>
                <div className="form-group d-flex flex-column justify-content-start mt-0" style={{ marginTop: '20px' }}>
                  <label style={{ textAlign: 'left' }}>Email</label>
                  <input
                    type="email"
                    className="form-control col-12 p-2"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Enter email"
                    name="email"
                    {...register("email", { required: true })}
                    style={{ height: '50px', }}
                  />
                </div>
                <span className='text-danger d-flex justify-content-start m-1'>{errors.email?.type === "required" ? "Email is required." : ""}</span>
                <div className="form-group d-flex flex-column  justify-content-start" style={{ marginTop: '10px' }}>
                  <label style={{ textAlign: 'left' }}>Password</label>
                  <div className="input-group"> {/* Wrap the input and icon inside an input-group */}

                    <input
                      type={passwordVisible ? 'text' : 'password'} // Step 4: Toggle the input type based on visibility state
                      className="form-control col-12 p-3"
                      id="exampleInputPassword1"
                      placeholder="Password"
                      style={{ height: '50px', borderRight: 'none' }}
                      name="password"
                      {...register("password", { required: true })}
                    />
                    <div className="input-group-append"> {/* Add the eye icon here */}
                      <span className="input-group-text " style={{ borderLeft: 'none', background: 'white', height: '50px', marginLeft: '-5px' }} onClick={togglePasswordVisibility}>
                        {passwordVisible ? <AiOutlineEyeInvisible size={25} /> : <AiFillEye size={25} />} {/* Replace the dimensions with the appropriate size */}
                      </span>
                    </div>
                  </div>
                </div>
                <span className='text-danger d-flex justify-content-start m-1'>{errors.password?.type === "required" ? "Password is required." : ""}</span>
                {loading ? <button className="btn col-12 mt-3 p-2 BTN_Color" disabled style={{ color: "white" }} type="submit">  Login...<span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                /></button> : <button className="btn col-12 mt-3 p-2 BTN_Color" style={{ color: "white" }} type="submit">Login</button>}
              </form>
            </div>
          </Col>
        </div>
      </div>
    </>
  )
}

export default Login
