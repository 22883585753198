import React, { useEffect, useState } from 'react'
import Card from 'react-bootstrap/Card';
import track from "../assets/img/track.svg"
import axios from 'axios';
import Tableskeleton from './Tableskeleton';
import moment from "moment/moment";
import { toast } from "react-toastify";
import { handleError } from "../Utilities/HandleError";
import { useNavigate } from 'react-router-dom';


const TransportList = () => {
let navigate =useNavigate()
    const [tarnsporterList, setTransportList] = useState([]);
    const [loading2, setLoading2] = useState(false)
    useEffect(() => {
       
            getBidDashborad();
            
       
    }, []);
    // ----------------------Dashboard card api----------------------
   
    const getBidDashborad = () => {
setLoading2(true)
        const config = {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-type": "application/json",
                "Authorization": `bearer ` + localStorage.getItem('adroit_token'),
            }
        };
        let url = process.env.REACT_APP_BASEURL + "vendors/"
        axios.get(url, config)
            .then((res) => {
                setTransportList(res.data.data)
                setLoading2(false)
            })
            .catch(err => {
                setLoading2(false)
                const getErrRes = handleError(err);
                if (getErrRes === 401) {
                    toast.error("Something went wrong.", {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 4000,
                        theme: "colored",
                    });
                } else {
                    toast.error(getErrRes ? getErrRes : "Something went wrong.", {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 4000,
                        theme: "colored",
                    });
                }
            }
            )
    }
    
 
    return (
        <>
            <div className='container-fluid mt-4'>
               
                <div className='row mt-2 mb-5'>
                    <div className='d-flex justify-content-between'>
                        <span><h3 className='p-3'>Transporter List</h3></span>
                        <span className='pt-3 pe-3'><button onClick={() => { navigate('/transporterdetail') }} className='btn text-white BTN_Color'>Add Transporter</button></span>
                    </div>

                    <div className='col'>
                        <Card className='rounded-4 shadow border-0'>
                            <div className='table-responsive my-3'>
                                <table className="table">
                                    <tbody>
                                        {loading2 ? <Tableskeleton /> :
                                            tarnsporterList.length > 0 && tarnsporterList.map((item, index) => (
                                                <tr className='align-middle ' key={index} >
                                                    {/* <th className='align-middle p-4'>Bid ID #{item.transporter_name} <div className='fw-normal fs-6'>Product:- {item.product_name}</div></th> */}
                                                    {/* <div>{JSON.stringify(process.env.REACT_IMAGE_BASEURL + item.image)}</div> */}
                                                    <th className='align-middle p-4'>{item.image === '' || item.iamge === null ? <img style={{ width: '100px', height: '100px', borderRadius: '10px' }} src={require('../assets/img/placeholder.jpeg')}></img> :<img style={{width:'100px',height:'100px', borderRadius:'10px'}} src={`https://bidease.theradixlab.com/media/${item.image}` }></img>} </th>
                                                    <th className='align-middle p-4'>{item.transporter_name}<div className='fw-normal fs-6'>{item.address}</div></th>
                                                    <th className='align-middle p-4'>{item.head_office_mobile} </th>
                                                    <th className='align-middle p-4'>{item.branch_office_mobile} </th>
                                                    <th className='align-middle p-4'><span className='mt-3 row'>
                                                        {/* <span className='col-xl-6 col-lg-12 col-md-12 col-sm-12'><img
                                                            src={track}
                                                            className="img-fluid mx-1 mt-1 mb-0"
                                                            style={{ width: "90%", height: "80%" }}
                                                            alt=""
                                                        /></span> */}
                                                        <span className='col-xl-6 col-lg-12 col-md-12 col-sm-12 '><button onClick={() => {

                                                        }} className='btn px-3 text-white BTN_Color'>View</button></span>
                                                    </span></th>
                                                </tr>
                                            ))}
                                        {!loading2 && tarnsporterList?.length === 0 && (
                                            <Card className="border-0 ">
                                                <Card.Body>
                                                    <h6 className="align-middle text-center fs-3 my-5 py-5">
                                                        No Data Found
                                                    </h6>
                                                </Card.Body>
                                            </Card>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </Card>
                    </div>
                </div>
              
            
            </div>
        </>
    )
}

export default TransportList;
